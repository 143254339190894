import C from '../constants';

export const setConferenceOn = (conference) => ({
    type: C.CONFERENCE_ON,
    conference
})

export const setConferenceOff = () => ({
    type: C.CONFERENCE_OFF,
})

export const setConferenceHoldOn = () => ({
    type: C.CONFERENCE_HOLD_ON,
})

export const setConferenceHoldOff = () => ({
    type: C.CONFERENCE_HOLD_OFF,
})

export const setConferenceRecOn = () => ({
    type: C.CONFERENCE_REC_ON,
})

export const setConferenceRecOff = () => ({
    type: C.CONFERENCE_REC_OFF,
})

export const setConferenceLockOn = () => ({
    type: C.CONFERENCE_LOCK_ON,
})

export const setConferenceLockOff = () => ({
    type: C.CONFERENCE_LOCK_OFF,
})

export const setConferenceMuteOff = () => ({
    type: C.CONFERENCE_MUTE_OFF,
})

export const setConferenceMuteMute = () => ({
    type: C.CONFERENCE_MUTE_MUTE,
})

export const setConferenceMuteLecture = () => ({
    type: C.CONFERENCE_MUTE_LECTURE,
})

export const setConferenceMuteControl = (status) => ({
    type: C.CONFERENCE_MUTE_CONTROL,
    status
})

export const setQAmode = (status) => ({
    type: C.CONFERENCE_QAMODE,
    status: status
})

export const addRepoFile = (file) => ({
    type: C.REPO_ADD_FILE,
    file: file
})

export const addRepoFiles = (files) => ({
    type: C.REPO_ADD_FILES,
    files: files
})

export const clearRepoFiles = () => ({
    type: C.REPO_CLEAR_FILES
})

export const addRecordingsFile = (file) => ({
    type: C.RECORDING_ADD_FILE,
    file: file
})

export const addRecordingsFiles = (files) => ({
    type: C.RECORDING_ADD_FILES,
    files: files
})

export const clearRecordingsFiles = () => ({
    type: C.RECORDING_CLEAR_FILES
}) 
