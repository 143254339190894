import React, { Component } from 'react';
import { Input, InputGroup } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faPhoneSlash, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-regular-svg-icons'

import { connect } from 'react-redux';

import update from 'immutability-helper';

const Config = require('../../config/config');

class DialoutItem extends Component {

    /*
    NEW DIALOUT REQUEST
    RT DIALOUT <requestId> <phoneNumber> <didNumber> <participantCode>
    > RT DIALOUT 7 3478121225 5073444 76767
    
    ANSWERED
    < NOTIFY-JOIN 10189066 audio Foreign 0 Speaker False False False False False True True True 1575967903 "5073444" "5073444" "" 0 0 0
    < RESPONSE 7 3 "10189066"
    
    DROP
    < NOTIFY-DROP 10189066 BRIDGE "BUSY"
    
    */

    statuses = {
        calling: "C",
        answered: "A",
        dropped: "D",
    };

    constructor(props) {
        super(props);

        const baseRandomValue = Math.floor(Math.random() * 9000) + 1;

        var defaultList = {};
        if (false && Config.debug) { // TODO
            defaultList = {
                "3471234546": {
                    phoneNumber: "3471234546",
                    status: this.statuses.answered,
                    iteration: 1,
                    requestId: 1,
                    callCode: -1
                },
                "3471234500": {
                    phoneNumber: "3471234500",
                    status: this.statuses.calling,
                    iteration: 1,
                    requestId: 1,
                    callCode: -1
                },
                "3471220546": {
                    phoneNumber: "3471220546",
                    status: this.statuses.calling,
                    iteration: 1,
                    requestId: 1,
                    callCode: -1
                },
                "3471222246": {
                    phoneNumber: "3471222246",
                    status: this.statuses.calling,
                    iteration: 1,
                    requestId: 1,
                    callCode: -1
                },
                "3001234546": {
                    phoneNumber: "3001234546",
                    status: this.statuses.calling,
                    iteration: 1,
                    requestId: 1,
                    callCode: -1
                },
            };
        }

        this.state = {
            socketInitialized: false,
            dialoutPhoneNumber: '',
            dialoutFormVisible: false,
            list: defaultList,
            iteration: 0,
            requestId: baseRandomValue,
        };
    }

    componentDidMount() {
        console.log('DialoutItem DidMount', { liveConference: this.props.liveConference });
        const { childRef } = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const { childRef } = this.props;
        childRef(undefined);
    }

    isPhoneCalled(phoneNumber) {
        console.log("isPhoneCalled", phoneNumber, this.state.list, this.state.list["" + phoneNumber] || false)
        return this.state.list["" + phoneNumber] || false;
    }

    setupSocket() {
        if (this.state.socketInitialized) return;
        this.setState({
            socketInitialized: true,
        });

        this.props.socketIO.on("loginemit", async (_data) => {
            var datas = _data.split("\n");
            console.log('TELNET DIALOUT', datas);

            datas.forEach(data => {
                if ((data || '').length === 0) return;

                var dataTokens = data.split(' ');
                // console.log('TELNET DIALOUT TOKENS', dataTokens);
                if (dataTokens.length > 16 && dataTokens[0] === "NOTIFY-JOIN" && this.state.list[dataTokens[16]]) {
                    var phoneNumber = dataTokens[16];
                    console.log('TELNET DIALOUT TOKENS NOTIFY', phoneNumber);
                    const list = this.state.list;
                    const iteration = this.state.iteration + 1;
                    list[phoneNumber].status = this.statuses.answered;
                    list[phoneNumber].iteration = iteration;
                    list[phoneNumber].callCode = dataTokens[1];
                    this.setState({
                        list: list,
                        iteration: iteration
                    })
                } else if (dataTokens.length > 4 && dataTokens[0] === "NOTIFY-DROP" && dataTokens[3] === "BUSY") {
                    var requestId = dataTokens[1];
                    console.log('TELNET DIALOUT TOKENS DROP', requestId);
                    var el = false;
                    const iteration = this.state.iteration + 1;
                    this.state.list.forEach(_el => {
                        if (!el && _el.requestId === requestId) {
                            el = Object.assign({}, _el, {
                                status: this.statuses.dropped
                            });
                        }
                    });
                    if (el) {
                        this.setState({
                            list: update(this.state.list, {
                                $merge: {
                                    [el.phoneNumber]: el
                                }
                            }),
                            iteration: iteration
                        })
                    }
                } else if (dataTokens.length > 1 && dataTokens[0] === "NOTIFY-TERMINATION") {
                    this.setState({
                        dialoutPhoneNumber: '',
                        dialoutFormVisible: false,
                        list: {},
                        iteration: 0,
                    });
                }
            });
        });
    }

    /**
     * Activate form
     */
    showDialoutForm = (val) => {
        val && this.setupSocket();
        this.setState({
            dialoutFormVisible: val || false
        })
    }

    /**
     * Check if the input value is a valid phone number.
     * @param
     */
    isValidPhoneNumber = (n) => {
        var m = n.replace(/[+| |,]/g, "");
        return m.length > 3 && m.length < 12 && m === n;
    }

    dialoutNumber = () => {
        const phoneNum = (this.state.dialoutPhoneNumber || "").trim();
        if (!this.isValidPhoneNumber(phoneNum)) return;

        const participantAccessCode = (this.props.participantAccessCode || "").trim();

        console.log("dialoutNumber - calling number ", {
            dialoutPhoneNumber: phoneNum,
            dialInNumber: this.props.dialInNumber,
            participantAccessCode: participantAccessCode,
        });
        const requestId = this.state.requestId + 1;
        const iteration = this.state.iteration + 1;

        const newEl = {
            [phoneNum]: {
                phoneNumber: phoneNum,
                status: this.statuses.calling,
                iteration: iteration,
                requestId: requestId,
                callCode: -1
            }
        };
        this.setState({
            list: update(this.state.list, { $merge: newEl }),
            dialoutPhoneNumber: '',
            requestId: requestId,
            iteration: iteration
        })
        this.props.socketIO.emit('dialout', requestId, phoneNum, this.props.dialInNumber, participantAccessCode);
    }

    handleDialoutChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    _handleDialoutKeyPress = e => {
        if (e.key === 'Enter') {
            this.dialoutNumber();
        }
    }


    render() {
        if (!this.props.isModerator) {
            return;
        }

        return (
            <div className={this.props.className}>
                <div>Dial-out Number</div>
                {this.props.liveConference ?
                    !this.state.dialoutFormVisible ?
                        <small><a href="#dialout" onClick={(e) => { e.preventDefault(); this.showDialoutForm(true); }}><i className="fa fa-phone"></i> Call phone number</a></small>
                        :
                        <InputGroup className="mb-12">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="flag-icon flag-icon-it"></i>
                                </span>
                            </div>
                            <Input id="dialoutPhoneNumber" type="text" placeholder="Phone Number" onChange={this.handleDialoutChange} onKeyPress={this._handleDialoutKeyPress} value={this.state.dialoutPhoneNumber} />
                        </InputGroup>
                    :
                    <small><FontAwesomeIcon icon={faHandPaper} /> <i>Available only on Live Conference</i></small>
                }

                {
                    Object.keys(this.state.list).map((_item, key) => {
                        const item = this.state.list[_item];
                        var icon = faAngleDoubleRight;
                        if (item.status === this.statuses.answered) icon = faPhoneAlt;
                        if (item.status === this.statuses.dropped) icon = faPhoneSlash;
                        return <div key={key} className="mt-1"><FontAwesomeIcon icon={icon} className="mr-1" /> {item.phoneNumber}</div>
                    })
                }
            </div>
        )
    }
}

// These props come from the application's
// state when it is started
const mapStateToProps = state => ({
    isModerator: state.authorization.isModerator,
    dialInNumber: state.authorization.dialInNumber,
    participantAccessCode: state.authorization.participantAccessCode,
    socketIO: state.socket.socketIO,
    liveConference: state.conference.liveConference,
})

const mapDispatchToProps = dispatch => ({
})


export default connect(mapStateToProps, mapDispatchToProps)(DialoutItem);
