import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
    Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Alert,
    Card, CardHeader, CardBody,
    Button, ButtonGroup,
    Label, Input,
    UncontrolledTooltip,
    Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import Sound from 'react-sound';
import classnames from 'classnames';
import moment from 'moment';
import { Resizable, ResizableBox } from 'react-resizable';

import RestRequest from '../../lib/RestRequest';
import Participant from '../Participant/Participant';
import OpenViduSession from '../VideoRoom/VideoRoomComponent';
// import OpenViduSespopenvision from 'openvidu-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faEye, faInfoCircle, faPhone, faQuestionCircle, faVolumeUp, faExternalLinkAlt, faClipboard, faStop, faPlay, faTrash, faArrowDown, faFolder, faUsers, faMicrophone, faAlignJustify, faVideo } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios';

//Redux
import { connect } from 'react-redux'
import { setQAmode, setConferenceMuteLecture, setConferenceMuteOff, setConferenceMuteControl, addRepoFiles, clearRepoFiles, addRecordingsFiles, clearRecordingsFiles } from '../../redux/actions/ConferenceAction';
import { updateAdditionalSession, deleteAddSession } from '../../redux/actions/AdditionalSessionAction';
import { stopWebRTCSession } from '../../redux/actions/WebRTCAction';

import './conference.css';

const Config = require('../../config/config');

class ConferenceTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            sessionId: 0,
            accept: '',
            dropzoneActive: false,
            repoActiveTab: '1',
            recordings: {},
            recordingPlayingId: '-1',
        }

        this.handlerJoinSessionEvent = this.handlerJoinSessionEvent.bind(this);
        this.handlerLeaveSessionEvent = this.handlerLeaveSessionEvent.bind(this);
        this.handlerErrorEvent = this.handlerErrorEvent.bind(this);
        this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.openviduSession = React.createRef();
    }

    modalToggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleRepoTab = (val) => {
        this.setState({
            repoActiveTab: val
        });
    }


    holdCaller = (value, sessionId) => {
        console.log('holdCaller', sessionId, value);

        if (sessionId > 0) {
            if (value) {
                RestRequest.sendCallCommand(sessionId, { "holdMode": 0 }).catch(e => e);
            }
            else {
                RestRequest.sendCallCommand(sessionId, { "holdMode": 1 }).catch(e => e);
            }
        }
    }

    muteCaller = (value, sessionId) => {
        console.log('muteCaller', sessionId, value);

        if (sessionId > 0) {
            if (value) {
                RestRequest.sendCallCommand(sessionId, { "muteMode": 0 }).catch(e => e);
            }
            else {
                RestRequest.sendCallCommand(sessionId, { "muteMode": 1 }).catch(e => e);
            }
        }
    }

    dropCaller = () => {
        console.log(this.state); // NOGIT
        if (this.state.sessionId > 0) {
            console.log("dropCaller", this.state.sessionId);
            RestRequest.deleteCall(this.state.sessionId);
            this.props.delAddSession(this.state.sessionId);
        }
        this.modalToggle();
    }

    qaEngage = (sessionId) => {
        RestRequest.sendCallCommand(sessionId, { "qaMode": 1 })
            .then(() => {
                console.log('Q&A Engage');
            })
            .catch(e => {
                console.log('qaEngage (CATCH)', e);
            });
    }

    qaDisengage = (sessionId) => {
        RestRequest.sendCallCommand(sessionId, { "qaMode": 0 })
            .then(() => {
                console.log('Q&A Engage');

            })
            .catch(e => {
                console.log('qaDisengage (CATCH)', e);
            });
    }

    toggleQeAQueue = (e) => {
        if (e.target.checked) {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "qaMode": 1 })
                .then(() => {
                    this.props.setQAmode(true);
                    this.props.setMuteLecture();
                    this.props.setMuteControl(false);
                })
                .catch(e => {
                    console.log('toggleQeAQueue (CATCH)', e);

                })
        }
        else {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "qaMode": 0 })
                .then(() => {
                    this.props.setQAmode(false);
                    this.props.setMuteOff();
                    this.props.setMuteControl(true);
                })
                .catch(e => {
                    console.log('toggleQeAQueue (CATCH)', e);

                })
        }
    }

    clearQeAQueue = () => {
        RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "qaMode": 4 })
            .then(() => {
                console.log('Q&A Clear');
            })
            .catch(e => {
                console.log('clearQeAQueue (CATCH)', e);
            });
    }

    qaEngageNext = () => {
        RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "qaMode": 2 })
            .then(() => {
                console.log('Q&A Engage Next');
            })
            .catch(e => {
                console.log('qaEngageNext (CATCH)', e);
            });
    }

    secondsToTime = (seconds) => {
        return new Date(seconds * 1000).toISOString().substr(11, 8);
    }

    disconnectCall = (sessionId) => {
        this.setState({
            popupTitle: 'Confirm operation',
            popupMessage: `Are you sure you want to disconnect this caller?`,
            modal: true,
            sessionId: sessionId
        })
    }

    updateAdditionalSession = (additionalSession) => {
        this.props.updateSession(additionalSession);
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDrop = (files) => {
        const configHeaders = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const formData = new FormData();
        formData.append('file', files[files.length - 1])

        axios.post(`${Config.apiServerUrl}/repository/addFiles/${this.props.conferenceNumber}`, formData, configHeaders)
            .then(res => {
                this.setState({
                    dropzoneActive: false
                });
                if (this.props.socketIO !== null) {
                    this.props.socketIO.emit('addfile');
                }
                this.props.clearRepoFiles();
                this.fetchRepository();
            })
            .catch(err => {
                if (err.response) {
                    switch (err.response.status) {
                        case 413:
                        case 403:
                            alert(err.response.data.message);
                            break;

                        default:
                            alert(err.response.status, err.response.statusText);
                    }
                }

                this.setState({
                    dropzoneActive: false
                });
            })

    }

    applyMimeTypes = (event) => {
        this.setState({
            accept: event.target.value
        });
    }

    delRepoFile = (id) => {
        axios.delete(`${Config.apiServerUrl}/repository/delFile/${id}`)
            .then(res => {
                this.props.clearRepoFiles();
                this.fetchRepository();
                if (this.props.socketIO !== null) {
                    this.props.socketIO.emit('delfile');
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 404) return;
                console.log('ConferenceTable delRepoFile', err);
            })
    }

    fetchRepository = () => {
        // console.log("Fetching repo: conference " + this.props.conferenceNumber);
        axios.get(`${Config.apiServerUrl}/repository/getFiles/${this.props.conferenceNumber}`)
            .then(res => {
                this.props.clearRepoFiles();
                this.props.addRepoFiles(res.data.response);
            })
            .catch(err => {
                if (err.response && err.response.status === 404) return;
                console.log('ConferenceTable fetchRepo', err);
            })
    }

    copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    handleSongLoading = (a) => {
        // console.log("handleSongLoading", a);
    }

    handleSongPlaying = (a) => {
        // console.log("handleSongPlaying", a);
    }

    handleSongFinishedPlaying = (a) => {
        let newRec = this.state.recordings;
        let playingId = ('' + this.state.recordingPlayingId) || '-1';
        if (playingId >= 0) {
            newRec[playingId] = { "status": Sound.status.STOPPED };
        }
        this.setState({
            recordings: newRec,
            recordingPlayingId: -1
        })
    }

    togglePlaying = (index) => {
        let newRec = this.state.recordings;
        let playingId = ('' + this.state.recordingPlayingId) || '-1';
        if (!newRec[index]) newRec[index] = { "status": Sound.status.STOPPED };
        newRec[index]["status"] = newRec[index]["status"] === Sound.status.PLAYING ? Sound.status.STOPPED : Sound.status.PLAYING;
        if (newRec[index]["status"] === Sound.status.PLAYING) playingId = index;
        this.setState({
            recordings: newRec,
            recordingPlayingId: playingId
        })
    }


    handlerJoinSessionEvent = (e) => {
        console.log("handlerJoinSessionEvent", e);
    }
    handlerLeaveSessionEvent = (e) => {
        console.log("handlerLeaveSessionEvent", e);
        this.props.stopWebRTCSession();
    }
    handlerErrorEvent = (e) => {
        console.log("handlerErrorEvent", e);
    }
    handleChangeSessionId(e) {
        this.setState({
            webRTCSessionId: e.target.value,
        });
    }
    handleChangeUserName(e) {
        this.setState({
            webRTCUserName: e.target.value,
        });
    }

    render() {
        // eslint-disable-next-line no-extend-native
        Date.prototype.addHours = function (h) {
            this.setTime(this.getTime() + (h * 60 * 60 * 1000));
            return this;
        }

        const colActiveCalls = [{
            Header: 'Caller ID',
            accessor: 'caller'
        },
        {
            Header: 'Caller Name',
            accessor: 'customName'
        },
        {
            Header: 'Call Begin',
            accessor: 'created',
            maxWidth: 100,
            Cell: row => {
                const oldDate = new Date(row.row.created);
                return oldDate.addHours(2).toISOString().substr(11, 8)
            }
        },
        {
            Header: 'Duration',
            accessor: 'duration',
            maxWidth: 100,
            Cell: row => (
                this.secondsToTime(row.row.duration)
            )
        },
        ];
        if (this.props.isModerator) {
            colActiveCalls.push(
                {
                    Header: 'Role',
                    accessor: 'role',
                    maxWidth: 50,
                    style: { textAlign: 'center' },
                    Cell: row => {
                        switch (row.row.role) {
                            case 1:
                                if (row.original.codec.toUpperCase() === 'NOVOICE') {
                                    return <FontAwesomeIcon icon={faEye} color="orange" />
                                }
                                return <FontAwesomeIcon icon={faPhone} color="orange" />

                            case 2:
                                if (row.original.codec.toUpperCase() === 'NOVOICE') {
                                    return <FontAwesomeIcon icon={faEye} color="blue" />
                                }
                                return <FontAwesomeIcon icon={faPhone} color="blue" />

                            default:
                                return <FontAwesomeIcon icon={faEye} />
                        }
                    }
                });
            colActiveCalls.push(
                {
                    Header: 'Hold',
                    accessor: 'hold',
                    maxWidth: 50,
                    style: { textAlign: 'center' },
                    Cell: row => (
                        (row.original.role > 0 && row.original.codec.toUpperCase() !== 'NOVOICE') ?
                            <Label className="switch switch-text switch switch-primary switch-sm ">
                                <Input id="ckbQeAqueue" type="checkbox" className="switch-input" onChange={() => this.holdCaller(row.original.holdMode, row.original.id)} checked={row.original.holdMode} />
                                <span className="switch-label" data-on="On" data-off="Off"></span>
                                <span className="switch-handle"></span>
                            </Label>
                            : null
                    )
                });
            colActiveCalls.push(
                {
                    Header: 'Mute',
                    accessor: 'mute',
                    maxWidth: 50,
                    style: { textAlign: 'center' },
                    Cell: row => (
                        (row.original.role > 0 && row.original.codec.toUpperCase() !== 'NOVOICE') ?
                            <Label className="switch switch-text switch switch-primary switch-sm ">
                                <Input id="ckbMuteMode" type="checkbox" className="switch-input" onChange={() => this.muteCaller(row.original.muteMode, row.original.id)} checked={row.original.muteMode} />
                                <span className="switch-label" data-on="On" data-off="Off"></span>
                                <span className="switch-handle"></span>
                            </Label>
                            : null
                    )
                });
            colActiveCalls.push(
                {
                    Header: 'QA',
                    accessor: 'qa',
                    maxWidth: 50,
                    style: { textAlign: 'center' },
                    Cell: row => {
                        if (row.original.role > 0 && row.original.codec.toUpperCase() !== 'NOVOICE') {
                            switch (row.original.qaMode) {
                                case 1:
                                    return <FontAwesomeIcon onClick={() => this.qaEngage(row.original.id)} icon={faQuestionCircle} />
                                case 2:
                                    return <FontAwesomeIcon onClick={() => this.qaDisengage(row.original.id)} icon={faVolumeUp} />
                                default:
                                    return null;
                            }
                        }
                        return null;
                    }
                });
            colActiveCalls.push(
                {
                    Header: 'Drop',
                    accessor: 'drop',
                    maxWidth: 50,
                    style: { textAlign: 'center' },
                    Cell: row => (
                        <FontAwesomeIcon icon={faTimes} onClick={() => {
                            this.setState({
                                popupTitle: 'Confirm operation',
                                popupMessage: `Are you sure you want to disconnect this caller?`,
                                modal: true,
                                sessionId: row.original.id
                            })
                        }} />
                    )
                });
        }


        const colActiveSpeakers = [{
            Header: 'Caller Info',
            accessor: 'caller'
        },
        {
            Header: 'Volume',
            accessor: 'volume',
            minWidth: 150,
            Cell: row => (
                <div
                    style={{
                        width: '150px',
                        height: '100%',
                        backgroundColor: '#dadada',
                        borderRadius: '2px'
                    }}
                >
                    <div
                        style={{
                            width: `${row.original.volume * 10}px`,
                            height: '100%',
                            backgroundColor: row.original.volume > 10 ? '#ff2e00'
                                : row.original.volume > 4 ? '#ffbf00'
                                    : '#85cc00',
                            borderRadius: '2px',
                            // transition: 'all .2s ease-out'
                        }}
                    />
                </div>
            )
        }
        ]

        const colQEA = [{
            Header: 'Caller Info',
            accessor: 'caller'
        },
        {
            Header: 'Mute',
            accessor: 'isMuted',
            maxWidth: 70,
            style: { textAlign: 'center' },
            Cell: row => (
                <Label className="switch switch-text switch switch-primary switch-sm ">
                    <Input id="ckbQeAqueue" type="checkbox" className="switch-input" onChange={() => this.muteCaller(row.original.isMuted, row.original.sessionId)} checked={row.original.isMuted} />
                    <span className="switch-label" data-on="On" data-off="Off"></span>
                    <span className="switch-handle"></span>
                </Label>
            )
        },
        {
            Header: 'QA',
            accessor: 'qaMode',
            maxWidth: 70,
            Cell: row => (
                (row.original.qaMode === 1 ?
                    <FontAwesomeIcon onClick={() => this.qaEngage(row.original.id)} icon={faQuestionCircle} />
                    :
                    <FontAwesomeIcon onClick={() => this.qaDisengage(row.original.id)} icon={faVolumeUp} />
                )
            )
        },
        ]

        const colRepository = [{
            Header: 'Filename',
            accessor: 'file_name'
        },
        {
            Header: 'Type',
            accessor: 'type',
            maxWidth: 70
        },
        {
            Header: 'Size',
            accessor: 'size',
            maxWidth: 70
        },
        {
            Header: '',
            accessor: 'url',
            minWidth: 50,
            style: { textAlign: 'center' },
            Cell: row => (
                <div>
                    <a id={`openLink_${row.index}`} href={row.original.file_url} target="_target" className="mr-1">
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                    <UncontrolledTooltip placement="top" target={`openLink_${row.index}`}>Open link</UncontrolledTooltip>

                    <a id={`copyLink_${row.index}`} href="#copyLink" onClick={(e) => { e.preventDefault(); this.copyToClipboard(row.original.file_url); }} className="mr-1">
                        <FontAwesomeIcon icon={faClipboard} />
                    </a>
                    <UncontrolledTooltip placement="top-start" target={`copyLink_${row.index}`}>Copy link</UncontrolledTooltip>

                    {this.props.isModerator ?
                        <a id={`delLink_${row.index}`} href="#delLink" onClick={(e) => { e.preventDefault(); this.delRepoFile(row.original.id); }} className="mr-1">
                            <FontAwesomeIcon icon={faTrash} />
                        </a>
                        : null
                    }
                    {this.props.isModerator ?
                        <UncontrolledTooltip placement="top" target={`delLink_${row.index}`}>Remove link</UncontrolledTooltip>
                        : null
                    }
                </div>

            )
        }];


        const colRecordings = [
            {
                Header: '🔊',
                accessor: 'url',
                minWidth: 36,
                style: { textAlign: 'center' },
                Cell: row => (
                    <div>
                        <a className="btn btn-sm" id={`togglePlaying_${row.index}`} href="#p" onClick={(e) => {
                            e.preventDefault();
                            this.togglePlaying('' + row.index);
                        }
                        }>
                            {this.state.recordings[row.index] && this.state.recordings[row.index]["status"] === Sound.status.PLAYING ?
                                <FontAwesomeIcon icon={faStop} />
                                :
                                <FontAwesomeIcon icon={faPlay} />
                            }
                        </a>
                        <UncontrolledTooltip placement="top" target={`togglePlaying_${row.index}`}>Play/Pause recording</UncontrolledTooltip>
                    </div>
                )
            },
            {
                Header: '⬇️',
                accessor: 'url',
                minWidth: 36,
                style: { textAlign: 'center' },
                Cell: row => (
                    <div>
                        <a className="btn btn-sm" id={`downloadRecording_${row.index}`} href={`https://joinaudio4.joinconferencing.com${row.original.url}.mp3`} target="_target">
                            <FontAwesomeIcon icon={faArrowDown} />
                        </a>
                        <UncontrolledTooltip placement="top" target={`downloadRecording_${row.index}`}>Download recording</UncontrolledTooltip>

                        <Sound
                            url={`https://joinaudio4.joinconferencing.com${row.original.url}.mp3`}
                            playStatus={this.state.recordings[row.index] ? this.state.recordings[row.index]["status"] : Sound.status.STOPPED}
                            // playFromPosition={300 /* in milliseconds */}
                            onLoading={this.handleSongLoading}
                            onPlaying={this.handleSongPlaying}
                            onFinishedPlaying={this.handleSongFinishedPlaying}
                            loop={false}
                        />
                    </div>
                )
            },
            {
                Header: 'Date',
                accessor: 'created',
                Cell: row => (
                    <div>{(moment(row.original.created)).format("YYYY-MM-DD HH:mm")}</div>
                )
            }, {
                Header: 'Size',
                maxWidth: 82,
                style: { textAlign: 'right' },
                accessor: 'size'
            }, {
                Header: '👨🏻‍💻',
                maxWidth: 36,
                style: { textAlign: 'center' },
                accessor: 'participantCount'
            }, {
                Header: '⏱',
                maxWidth: 82,
                style: { textAlign: 'center' },
                accessor: 'duration'
            }];

        const { accept, dropzoneActive } = this.state;

        const overlayStyle = {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            padding: '2.5em 0',
            background: 'rgba(0,0,0,0.5)',
            textAlign: 'center',
            color: '#fff',
            zIndex: 99999,
        };


        return (
            <div className="animated fadeIn">
                <Modal isOpen={this.state.modal} toggle={this.modalToggle} className="modal-danger">
                    <ModalHeader toggle={this.modalToggle}>
                        {this.state.popupTitle}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.popupMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.dropCaller}>Yes</Button>{' '}
                        <Button color="secondary" onClick={this.modalToggle}>No</Button>
                    </ModalFooter>
                </Modal>

                {this.props.alert ?
                    <Row className="justify-content-center">
                        <Col md="8">
                            <Alert isOpen={true} color={this.props.alertColor}>
                                {this.props.alertMessage}
                            </Alert>
                        </Col>
                    </Row>
                    : null
                }

                {this.props.webRTCSessionId === '' ? '' :
                    <Row>
                        <Col style={{ zIndex: 1 }}>
                            <Card>
                                <CardHeader>
                                    <FontAwesomeIcon icon={faVideo} /> Data sharing
                                </CardHeader>

                                <ResizableBox id="video-session" width="100%" height={380} axis="y"
                                    onResizeStop={() => {
                                        console.log('onResizeStop', this.openviduSession.current);
                                        this.openviduSession.current.updateLayout();
                                    }}>

                                    <OpenViduSession
                                        id="opv-session"
                                        ref={this.openviduSession}
                                        sessionName={this.props.webRTCSessionId}
                                        user={this.props.webRTCUsername}
                                        token={this.props.webRTCToken}
                                        joinSession={this.handlerJoinSessionEvent}
                                        leaveSession={this.handlerLeaveSessionEvent}
                                        error={this.handlerErrorEvent}
                                        isModerator={this.props.isModerator}
                                    />
                                </ResizableBox>
                            </Card>
                        </Col>
                    </Row>
                }

                <Row>
                    {this.props.isModerator || this.props.participantPhoneNumber ?
                        <Col xl="8" md="12" style={{ zIndex: 1 }}>
                            <Card>
                                <CardHeader>
                                    <FontAwesomeIcon icon={faPhone} className="mr-1" />
                                    <strong>Active Calls</strong>

                                    <div className="card-actions-max">
                                        <a href="#calls">
                                            <small className="text-muted">Total active calls: {this.props.sessions.length}</small>
                                        </a>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {this.props.isGraphicMode === true ?
                                        <div id="floor">
                                            {
                                                this.props.sessions.map((session, index) => {
                                                    let participant;
                                                    participant = ((index === 0) ?
                                                        <div key={'dParticipant_' + index} id="meeting-table-top">
                                                            <img alt="" src="img/conference/top.jpg" style={{ height: 'auto', maxWidth: '100%' }} />

                                                            <Participant key={'Participant_' + index} id={'Participant_' + index} className="box center participant participant_top"
                                                                session={session}
                                                                onDisconnect={this.disconnectCall}
                                                                onUpdateSession={this.updateAdditionalSession}
                                                                onMute={this.muteCaller}
                                                                onHold={this.holdCaller}
                                                                hasData={false} />

                                                        </div>

                                                        :
                                                        participant = (index % 2 !== 0) ?
                                                            <div key={'dParticipant_' + index} className="meeting-table-content">
                                                                <img alt="" src="img/conference/content.jpg" style={{ height: 'auto', maxWidth: '100%' }} />
                                                                <Participant key={'Participant_' + index} id={'Participant_' + index} className="box left participant participant_left"
                                                                    session={session}
                                                                    onDisconnect={this.disconnectCall}
                                                                    onUpdateSession={this.updateAdditionalSession}
                                                                    onMute={this.muteCaller}
                                                                    onHold={this.holdCaller}
                                                                    hasData={false} />

                                                                {this.props.sessions[index + 1] ?
                                                                    <Participant key={'Participant_' + (index + 1)} id={'Participant_' + (index + 1)} className="box right participant participant_right"
                                                                        session={this.props.sessions[index + 1]}
                                                                        onDisconnect={this.disconnectCall}
                                                                        onUpdateSession={this.updateAdditionalSession}
                                                                        onMute={this.muteCaller}
                                                                        onHold={this.holdCaller}
                                                                        hasData={false} />
                                                                    : null}

                                                            </div>
                                                            :
                                                            null
                                                    );
                                                    return participant;
                                                })
                                            }
                                            {
                                                this.props.sessions.length > 0 ? <div id="meeting-table-bottom"><img alt="" src="img/conference/bottom.jpg" style={{ height: 'auto', maxWidth: '100%' }} /></div> : null
                                            }
                                        </div>

                                        :
                                        <ReactTable
                                            key="tableActiveCalls"
                                            data={this.props.sessions}
                                            columns={colActiveCalls}
                                            pageSizeOptions={[8, 15, 30]}
                                            defaultPageSize={8}
                                            filterable={false}
                                            style={{ fontSize: '12px' }}
                                            className="-striped -highlight"
                                            defaultSorted={[
                                                {
                                                    id: "created",
                                                    desc: false
                                                }
                                            ]}
                                        />

                                    }

                                </CardBody>
                            </Card>
                        </Col>
                        : null
                    }
                    <Col xl={this.props.isModerator || this.props.participantPhoneNumber ? "4" : null} className="fixed">
                        <Row>
                            {this.props.liveConference && this.props.isModerator ?
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <FontAwesomeIcon icon={faUsers} className="mr-1" />
                                            <strong>Active Speakers</strong>
                                        </CardHeader>
                                        <CardBody>
                                            <ReactTable
                                                key="tableActiveSpeakers"
                                                data={this.props.listActiveSpeaker}
                                                columns={colActiveSpeakers}
                                                defaultPageSize={30}
                                                showPagination={false}
                                                filterable={false}
                                                style={{ fontSize: '11px', height: '210px' }}
                                                className="-striped -highlight"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                                : null
                            }


                            <Col>
                                <Card>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink className={classnames({ active: this.state.repoActiveTab === '1' })} onClick={() => { this.toggleRepoTab('1'); this.fetchRepository(); }}>
                                                <FontAwesomeIcon icon={faFolder} className="mr-1" />
                                                <strong>Repository</strong>
                                            </NavLink>
                                        </NavItem>
                                        {this.props.liveConference && this.props.isModerator ?
                                            <NavItem>
                                                <NavLink className={classnames({ active: this.state.repoActiveTab === '2' })} onClick={() => { this.toggleRepoTab('2'); }}>
                                                    <FontAwesomeIcon icon={faMicrophone} className="mr-1" />
                                                    <strong>Recordings</strong>
                                                </NavLink>
                                            </NavItem>
                                            :
                                            null
                                        }
                                    </Nav>

                                    <TabContent activeTab={this.state.repoActiveTab}>
                                        <TabPane tabId="1">
                                            {this.props.isModerator ?
                                                <Dropzone
                                                    disableClick
                                                    style={{ position: "relative" }}
                                                    accept={accept}
                                                    onDrop={this.onDrop}
                                                    onDragEnter={this.onDragEnter}
                                                    onDragLeave={this.onDragLeave}
                                                    multiple={false}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div {...getRootProps()}>
                                                            {dropzoneActive ?
                                                                <div>
                                                                    <input {...getInputProps()} />
                                                                    <div style={overlayStyle}>
                                                                        <span className="badge badge-pill badge-primary">
                                                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" /> Drop single file (Max 10 Mb)...
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                            <div className="text-center mb-2">
                                                                <span className="badge badge-pill badge-light"><FontAwesomeIcon icon={faArrowDown} className="mr-1" /> Drag &amp; Drop file here!</span>
                                                            </div>
                                                            <ReactTable
                                                                key="tableRepository"
                                                                data={this.props.listRepository}
                                                                columns={colRepository}
                                                                defaultPageSize={5}
                                                                showPagination={true}
                                                                filterable={false}
                                                                style={{ fontSize: '11px', height: '210px' }}
                                                                className="-striped -highlight"
                                                            />
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                :
                                                <ReactTable
                                                    key="tableRepository"
                                                    data={this.props.listRepository}
                                                    columns={colRepository}
                                                    defaultPageSize={5}
                                                    showPagination={true}
                                                    filterable={false}
                                                    style={{ fontSize: '11px', height: '210px' }}
                                                    className="-striped -highlight"
                                                />
                                            }
                                        </TabPane>
                                        {this.props.liveConference && this.props.isModerator ?
                                            <TabPane tabId="2">
                                                <ReactTable
                                                    key="tableRecordings"
                                                    data={this.props.listRecordings}
                                                    columns={colRecordings}
                                                    defaultPageSize={5}
                                                    showPagination={true}
                                                    filterable={false}
                                                    style={{ fontSize: '11px', height: '210px' }}
                                                    className="-striped -highlight"
                                                />
                                            </TabPane>
                                            :
                                            null
                                        }
                                    </TabContent>
                                </Card>
                            </Col>

                            {this.props.liveConference && this.props.isModerator ?
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <FontAwesomeIcon icon={faAlignJustify} className="mr-1" />
                                            <strong>Q&A Queue</strong>
                                            <a href="#q" className="ml-1">
                                                <Label className="switch switch-text switch switch-primary switch-sm ">
                                                    <Input id="ckbQeAqueue" type="checkbox" className="switch-input" onChange={this.toggleQeAQueue} checked={this.props.qaMode} />
                                                    <span className="switch-label" data-on="On" data-off="Off"></span>
                                                    <span className="switch-handle"></span>
                                                </Label>
                                                {this.props.listQAqueue.length > 0 ?
                                                    <Label className="switch switch-sm switch-text switch-info float-right mb-0 mr-5">
                                                        <ButtonGroup size="sm">
                                                            <Button onClick={this.qaEngageNext}>Next</Button>
                                                            <Button onClick={this.clearQeAQueue}>Clear</Button>
                                                        </ButtonGroup>
                                                    </Label>
                                                    : null
                                                }
                                            </a>
                                        </CardHeader>
                                        <CardBody>
                                            <ReactTable
                                                key="tableQEA"
                                                data={this.props.listQAqueue}
                                                columns={colQEA}
                                                defaultPageSize={20}
                                                showPagination={false}
                                                filterable={false}
                                                style={{ fontSize: '11px', height: '210px' }}
                                                className="-striped -highlight"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                                :
                                null
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

// These props come from the application's
// state when it is started
const mapStateToProps = state => ({
    conferenceNumber: state.authorization.conferenceNumber,
    conferenceId: state.conference.conferenceId,
    liveConference: state.conference.liveConference,
    sessions: state.sessions.list,
    sessionCount: state.sessions.count,
    qaMode: state.conference.qaMode,
    sessionId: state.sessions.sessionId,
    listQAqueue: state.qaqueue.list,
    listActiveSpeaker: state.activeSpeaker.list,
    additionalSessions: state.additionalSessions.list,
    listRepository: state.repository.list,
    listRecordings: state.recordings.list,
    isGraphicMode: state.general.isGraphicMode,
    socketIO: state.socket.socketIO,
    isModerator: state.authorization.isModerator === 1,
    participantPhoneNumber: state.authorization.participantPhoneNumber,
    alert: state.general.alert,
    alertMessage: state.general.alertMessage,
    alertColor: state.general.alertColor,
    webRTCSessionId: state.webRTC.sessionId,
    webRTCToken: state.webRTC.token,
    webRTCUsername: state.webRTC.username,
})

const mapDispatchToProps = dispatch => ({
    setQAmode: (status) => dispatch(setQAmode(status)),
    setMuteOff: () => dispatch(setConferenceMuteOff()),
    setMuteLecture: () => dispatch(setConferenceMuteLecture()),
    setMuteControl: (status) => dispatch(setConferenceMuteControl(status)),
    updateSession: (additionalSession) => dispatch(updateAdditionalSession(additionalSession)),
    delAddSession: (sessionId) => dispatch(deleteAddSession(sessionId)),
    addRepoFiles: (files) => dispatch(addRepoFiles(files)),
    clearRepoFiles: () => dispatch(clearRepoFiles()),
    addRecordingsFiles: (files) => dispatch(addRecordingsFiles(files)),
    clearRecordingsFiles: () => dispatch(clearRecordingsFiles()),
    stopWebRTCSession: () => dispatch(stopWebRTCSession()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceTable);