import C from '../constants';

export const updateAdditionalSession = additionalSession => ({
    type: C.UPDATE_ADDSESSION,
    additionalSession,
})

export const deleteAddSession = sessionId => ({
    type: C.DELETE_ADDSESSION,
    sessionId  
})

export const clearAddSessions = () => ({
    type: C.CLEAR_ADDSESSIONS
})