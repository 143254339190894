import React, { Component } from 'react';
var pjson = require('../../../package.json');

class Footer extends Component {
  render() {
    return (
      <footer className="app-footer">
        <span><a href="http://www.joinconferencing.com/it/">Join Conference</a> &copy; 2018 All right reserved.</span>
        <span className="ml-auto">v. {pjson.version} - Powered by <a href="https://www.skianet.it/">Skianet</a></span>
      </footer>
    )
  }
}

export default Footer;
