import C from '../constants';

const initialState = {
    list: [],
};

function getSizeLabel(size) {
    if (size < 1000) return [size, "b"].join(" ");
    if (size < 1000000) return [(size / 1000).toFixed(2), "Kb"].join(" ");
    if (size < 1000000000) return [(size / 1000000).toFixed(2), "Mb"].join(" ");

    return size;
}

const recordings = (state = initialState, action) => {
    switch (action.type) {
        case C.RECORDING_ADD_FILES:
            const out = [];
            for (let i = 0; i < action.files.length; i++) {
                out.push({
                    file_name: "audio_" + i,
                    size: getSizeLabel(action.files[i].fileSize),
                    url: action.files[i].recordingUrl,
                    meetingId: action.files[i].meetingId,
                    created: action.files[i].created,
                    duration: action.files[i].recordingDuration + 's',
                    participantCount: action.files[i].participantCount,
                })
            }
            console.log("RecordingsReducer", state, action.files, [...state.list, ...out]);
            return {
                ...state,
                list: [...state.list, ...out]
            }

        case C.RECORDING_ADD_FILE:
            return {
                ...state,
                list: [...state.list, {
                    file_name: action.file_name,
                    size: getSizeLabel(action.fileSize),
                    url: action.recordingUrl,
                    meetingId: action.meetingId,
                    created: action.created,
                    duration: action.recordingDuration + 's',
                    participantCount: action.participantCount,
                }]
            }

        case C.RECORDING_CLEAR_FILES:
            state = Object.assign({}, state, initialState);
            return state;

        default:
            return state;
    }
}

export default recordings;