import C from '../constants';

export const addSession = session => ({
    type: C.ADD_SESSION,
    session,
})

export const updateSession = session => ({
    type: C.UPDATE_SESSION,
    session,
})

export const updateSessionAll = session => ({
    type: C.UPDATE_SESSION_ALL,
    session,
})

export const updateSessionDuration = (id, duration) => ({
    type: C.UPDATE_SESSION_DURATION,
    id,
    duration
})

export const deleteSession = id => ({
    type: C.DELETE_SESSION,
    id  
})

export const showSession = session => ({
    type: C.SHOW_SESSION    
})

export const clearSession = () => ({
    type: C.CLEAR_SESSIONS    
})

export const countSession = count => ({
    type: C.COUNT_SESSIONS,    
    count
})

export const addSessionId = sessionId => ({
    type: C.ADD_SESSION_ID,
    sessionId
})

export const addAudioKey = audioKey => ({
    type: C.ADD_AUDIO_KEY,
    audioKey: audioKey
})
