import C from '../constants'; 
 
const initialState = { 
    list: [], 
}; 

function getTypeLabel(type) { 
    var t = type.split("/"); 

    if (t.length < 2) t.push(""); 
    if (t[0] === "image") return "Image"; 
    if (t[0] === "text") return "Text"; 
    if (t[0] === "video") return "Video"; 
    if (t[1] === "pdf") return "PDF"; 
    if (t[1] === "zip") return "ZIP"; 
    if (type.indexOf("powerpoint") >= 0) return "PPT"; 

    return "File"; 
} 

function getSizeLabel(size) { 
    if (size < 1000) return [size, "b"].join(" "); 
    if (size < 1000000) return [(size / 1000).toFixed(2), "Kb"].join(" "); 
    if (size < 1000000000) return [(size / 1000000).toFixed(2), "Mb"].join(" "); 

    return size; 
} 

const repository = (state = initialState, action) => { 
    switch (action.type) { 
        case C.REPO_ADD_FILES: 
            return { 
                ...state, 
                list: [...state.list, ...action.files ] 
            } 

        case C.REPO_ADD_FILE: 
            return { 
                ...state, 
                list: [...state.list, {                     
                    file_name: action.file_name, 
                    type: getTypeLabel(action.type), 
                    size: getSizeLabel(action.size),
                    url: action.file_url 
                }] 
            } 

        case C.REPO_CLEAR_FILES:
            state = Object.assign({}, state, initialState);
            return state;        

        default: 
            return state; 
    } 
} 

export default repository;