import React, { Component } from 'react';
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import HeaderDropdown from './HeaderDropdown';
import classnames from 'classnames';

const Config = require('../../config/config');

class Header extends Component {

  constructor(props) {
    super(props);

    let environment = Config.development ? "development" : "production";
    if (!Config.development && window.location.hostname === "moderatordev.joinconferencing.com") {
      environment = "test";
    }

    this.state = {
      environment: environment,
    };

  }

  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  render() {
    return (
      <header className={classnames("app-header", "navbar", this.state.environment)}>
        {/* <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler> */}
        <NavbarBrand href="#"></NavbarBrand>
        {/* <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler> */}
        <Nav className="ml-auto" navbar>
          {/* <HeaderDropdown notif/>
          <HeaderDropdown tasks/>
          <HeaderDropdown mssgs/>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
          </NavItem>*/}
          <HeaderDropdown accnt />
        </Nav>
        {/* <NavbarToggler className="d-md-down-none" onClick={this.asideToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>         */}
        <NavbarToggler onClick={this.asideToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
      </header>
    );
  }
}

export default Header;
