import axios from 'axios';

const Config = require('../config/config');

/*
//  Servirà per autenticazione user/password
exports.whoAmI = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${Config.bridgeUrl}/subscribers/whoami`)
        .then(res => {         
            return res.data.response;                     
        })
        .catch(error => {
            console.log('AXIOS whoAmI', error); 
        })
    })
}
*/
axios.defaults.headers.common['Authorization'] = 'Basic c2tpYW5ldDpnZGg3NlA=';

export default {
    login(meetingNumber, accessCode) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/meetingRooms/${meetingNumber}`, { headers: { 'Authorization': 'Basic c2tpYW5ldDpnZGg3NlA=' } })
                .then(res => {
                    if (res.status === 200) {
                        res.data.keychain.forEach(key => {
                            if (key.accessCode === accessCode) {
                                resolve(res.data);
                            }
                        })
                    }
                    else {
                        reject('error');
                    }
                })
                .catch(error => {
                    console.log('AXIOS login', error);
                    reject('error');
                })
        })
    },

    loginByPhone(participantAccessCode, phoneNumber) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/calls?filter=accessCode%20eq%20${participantAccessCode}%20and%20caller%20eq%20${phoneNumber}`, { headers: { 'Authorization': 'Basic c2tpYW5ldDpnZGg3NlA=' } })
                .then(res => {
                    let found = false;
                    if (res.status === 200) {
                        res.data.calls.forEach(key => {
                            if (key.caller === phoneNumber) {
                                found = true;
                                resolve(key);
                            }
                        });
                    }
                    if (!found) {
                        reject('error');
                    }
                })
                .catch(error => {
                    console.log('AXIOS participant login', error);
                    reject('error');
                })
        })
    },

    loginByRoom(participantAccessCode) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/calls?filter=accessCode%20eq%20${participantAccessCode}`, { headers: { 'Authorization': 'Basic c2tpYW5ldDpnZGg3NlA=' } })
                .then(res => {
                    let found = false;
                    if (res.data.calls.length > 0) {
                        res.data.calls.forEach(el => {
                            if (el.accessCode === participantAccessCode) {
                                found = el;
                            }
                        });
                    }
                    found ? resolve(found) : reject('error');
                })
                .catch(error => {
                    console.log('AXIOS participant login', error);
                    reject('error');
                })
        })
    },

    logout() {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/subscribers/logout`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS logout', error);
                    reject(error);
                })
        })
    },

    getDidGroups(groupId) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/didGroups/${groupId}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS getDidGroups', error);
                    reject(error);
                })
        })
    },

    // Ex SOAP getConferences ==> MEETING 
    getMeetings(meetingNumber) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/meetings/${meetingNumber}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS getMeetings', error);
                    reject(error);
                })
        })
    },

    sendMeetingCommand(meetingNumber, command) {
        return new Promise((resolve, reject) => {
            axios.put(`${Config.bridgeUrl}/meetings/${meetingNumber}`, command)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS sendMeetingCommand', error);
                    reject(error);
                })
        })
    },

    deleteMeeting(meetingNumber) {
        return new Promise((resolve, reject) => {
            axios.delete(`${Config.bridgeUrl}/meetings/${meetingNumber}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS deleteMeetings', error);
                    reject(error);
                })
        })
    },

    // Ex SOAP getSessions ==> CALLS
    getCalls(meetingNumber) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/calls?filter=meetingNumber%20eq%20${meetingNumber}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS getCalls', error);
                    reject(error);
                })
        })
    },

    getCall(callId) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/calls/${callId}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS getCall', error);
                    reject(error);
                })
        })
    },

    sendCallCommand(callId, command) {
        return new Promise((resolve, reject) => {
            axios.put(`${Config.bridgeUrl}/calls/${callId}`, command)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS sendCallCommand', error);
                    reject(error);
                })
        })
    },

    deleteCall(callId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${Config.bridgeUrl}/calls/${callId}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    resolve();
                    // console.log('AXIOS deleteCall', error);
                    // reject(error);
                })
        })
    },

    getRecordings(meetingNumber) {
        return new Promise((resolve, reject) => {
            axios.get(`${Config.bridgeUrl}/mdrs?filter=meetingNumber%20eq%20${meetingNumber}%20and%20hasRecording%20eq%20true&limit=100&order=created%20desc`, { headers: { 'Authorization': 'Basic c2tpYW5ldDpnZGg3NlA=' } })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log('AXIOS getRecordings', error);
                    reject(error);
                })
        })
    }
}
