import C from '../constants';

const initialState = {
    asideActiveTab: '2',
    isGraphicMode: true,
    alert: false,
    alertMessage: "",
    alertColor: "info",
};

const general = (state = initialState, action) => {    
    switch (action.type) {
        case C.ASIDE_SET_ACTIVE_TAB:
            return {
                ...state,
                asideActiveTab: action.tab,
                asideSessionId: action.sessionId ? action.sessionId : 0
            }

        case C.SET_GRAPHIC_MODE:
            return {
                ...state,
                isGraphicMode: action.isGraphicMode
            }

        case C.SHOW_ALERT:
            return {
                ...state,
                alert: true,
                alertMessage: action.message,
                alertColor: action.color || "info",
            }

        case C.HIDE_ALERT:
            return {
                ...state,
                alert: false,
                alertMessage: "",
                alertColor: "info",
            }

        default:
            return state;
    }
}
export default general;