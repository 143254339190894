import C from '../constants';

const initialState = {
    isModerator: false,
    isLogged: false,
    conferenceNumber: 0,
    hostAccessCode: 0,
    participantAccessCode: 0,
    participantPhoneNumber: '',
    playBackNumber: 0,
    dialInNumber: 0,
    token: '',
    loginErrorMessage: ''
};

const auth = (state = initialState, action) => {    
    switch (action.type) {
        case C.SEND_LOGIN:
            state = Object.assign({}, state, {
                isLogged: false,
                conferenceNumber: action.conferenceNumber,
                hostAccessCode: action.hostAccessCode,
                
            });
            return state;

        case C.LOGIN_SUCCESS:
            state = Object.assign({}, state, {
                isLogged: true,
                isModerator: action.auth.isModerator,
                conferenceNumber: action.auth.conferenceNumber,
                participantAccessCode: action.auth.participantAccessCode,
                participantPhoneNumber: action.auth.participantPhoneNumber || "",
                hostAccessCode: action.auth.hostAccessCode || "",
                dialInNumber: action.auth.dialInNumber || "",
                playBackNumber: action.auth.playBackNumber || "",
                callId: action.auth.callId || "",
                token: action.auth.token,
                loginErrorMessage: ''
            });
            return state;

        case C.LOGIN_ERROR:
            state = Object.assign({}, state, {
                isLogged: false,
                loginErrorMessage: action.errorMessage
            });
            return state;

        case C.LOGIN_RESUME:
            state = Object.assign({}, state, initialState);
            return state;

        case C.SEND_LOGOUT:
            state = Object.assign({}, state, initialState);            
            return state;

        default:
            return state;
    }
}
export default auth;