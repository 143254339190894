import axios from 'axios';
import classnames from 'classnames';
import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux';
// import { ScrollUpButton } from 'react-scroll-up-button';
import { HashLoader } from "react-spinners";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, ButtonGroup, Card, CardHeader, CardBody, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import socketIOClient from "socket.io-client";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faAddressBook, faKey, faMicrophoneSlash, faMicrophone, faVideo } from '@fortawesome/free-solid-svg-icons'

import { clearActiveSpeaker, deleteActiveSpeaker, setActiveSpeaker } from '../../redux/actions/ActiveSpeakerAction';
import { clearAddSessions, updateAdditionalSession } from '../../redux/actions/AdditionalSessionAction';
import { addRepoFiles, clearRepoFiles, addRecordingsFiles, clearRecordingsFiles, setConferenceHoldOff, setConferenceHoldOn, setConferenceLockOff, setConferenceLockOn, setConferenceMuteControl, setConferenceMuteLecture, setConferenceMuteMute, setConferenceMuteOff, setConferenceOff, setConferenceOn, setConferenceRecOff, setConferenceRecOn, setQAmode } from '../../redux/actions/ConferenceAction';
import { asideSetActiveTab, setGraphicMode, showAlert, hideAlert } from '../../redux/actions/GeneralAction';
import { addQAqueue, clearQAqueue, delQAqueue } from '../../redux/actions/QAqueueAction';
import { addAudioKey, addSession, addSessionId, clearSession, countSession, deleteSession, updateSession, updateSessionAll, updateSessionDuration } from '../../redux/actions/SessionAction';
import { addSocket, delSocket } from '../../redux/actions/SocketAction';
import { sendLogout } from '../../redux/actions/AuthAction';
import { addWebRTCSession, stopWebRTCSession } from '../../redux/actions/WebRTCAction';

import RestRequest from '../../lib/RestRequest';
import webRTCRequest from '../../lib/WebRTCRequest';

import InfoParticipant from '../InfoParticipant';
import DialoutItem from './DialoutItem';

import './aside.css';

const Config = require('../../config/config');

class Aside extends Component {

    idInterval = 0;
    bridgeSocketReconnection = 0;
    asideMenuTranslateY = 0;

    // Wait for the client to enable this
    detectRecordableFlag = false;

    constructor(props) {
        super(props);

        console.log("Config", Config);

        let environment = Config.development ? "development" : "production";
        if (!Config.development && window.location.hostname === "moderatordev.joinconferencing.com") {
            environment = "test";
        }

        this.state = {
            modal: false,
            dialinModal: false,
            numClearAS: 0,
            loaders: false,
            isMuted: false,
            webRTCSessionName: 'Room' + this.props.conferenceNumber,
            webRTCUserName: '',
            enableDataSharing: environment !== 'production', // true - TODO to restore
            dialoutPhoneNumber: '',
            dialoutFormVisible: false,
            meetingAttributes: []
        };

        this.handlerJoinSessionEvent = this.handlerJoinSessionEvent.bind(this);
        this.handlerLeaveSessionEvent = this.handlerLeaveSessionEvent.bind(this);
        this.handlerErrorEvent = this.handlerErrorEvent.bind(this);
        this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.startWebRTCSession = this.startWebRTCSession.bind(this);
        this.closeWebRTCSession = this.closeWebRTCSession.bind(this);

        this.elementRef = React.createRef();
    }

    onUnload(event) {
        console.log("Triggering onUnload event");
        // if (this.props && this.props.sessionId && this.props.sessionId > 0) {
        this.props.logout();
        this.props.setConferenceOff();
        this.props.clearSession();
        this.props.clearActiveSpeaker();
        this.props.clearAddSessions();
        this.props.clearRepoFiles();
        this.props.clearRecordingsFiles();
        // }
    }

    onBeforeUnload(event) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to exit?';
    }

    onScroll() {
        let newAsideMenuTop = - Math.min(window.scrollY, 55);
        if (this.asideMenuTranslateY != newAsideMenuTop) {
            this.elementRef.current.style.transform = `translateY(${this.asideMenuTranslateY = newAsideMenuTop}px)`;
        }
    }

    modalToggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggle = (tab) => {
        if (this.props.asideActiveTab !== tab) {
            this.props.setActiveTab(tab);
        }
    }

    getUserProfile = (session) => {
        session.imageUrl = `img/conference/avatars/${session.id.toString().split('').pop()}.png`;
        session.typeData = 0;
        session.note = '';

        if (["PCMA", "GT22"].indexOf(session.codec) && !isNaN(parseFloat(session.phoneNumber))) {
            session.typeData = 2;

            axios.get(`${Config.apiServerUrl}/users/findByPhone/${session.phoneNumber}`)
                .then(success => {
                    const user = success.data.response;

                    if (user.length > 0) {
                        session.typeData = 1;
                        session.customName = `${user[0].last_name} ${user[0].first_name}`;
                        if (user[0].image_url) {
                            session.imageUrl = user[0].image_url;
                        }
                        this.props.addSession(session);

                        RestRequest.sendCallCommand(session.id, { "customName": `${user[0].last_name} ${user[0].first_name}` })
                            .catch(e => {
                                console.log('sendCallCommand customName (CATCH)', e);
                            })
                    } else {
                        //Se non ho i dati dall'app, verifico se ci sono i dati salvati in user_conference
                        axios.get(`${Config.apiServerUrl}/users/getProfileForRoom/${this.props.conferenceNumber}/${session.phoneNumber}`)
                            .then(resultDb => {
                                if (resultDb.data.status < 400) {
                                    const userDb = resultDb.data.response;

                                    if (userDb.length > 0) {
                                        session.customName = userDb[0].custom_name ? userDb[0].custom_name : session.phoneNumber;
                                        session.note = userDb[0].note;
                                        if (userDb[0].image_url) {
                                            session.imageUrl = userDb[0].image_url;
                                        }
                                    }
                                    else {
                                        session.customName = session.phoneNumber;
                                    }
                                    RestRequest.sendCallCommand(session.id, { "customName": session.customName })
                                        .catch(e => {
                                            console.log('sendCallCommand customName (CATCH)', e);
                                        })
                                }
                                this.props.addSession(session);
                            })
                            .catch(error => {
                                console.log('Aside - GetUserProfile (CATCH)', error);

                                session.customName = session.phoneNumber;
                                this.props.addSession(session);
                            })
                    }
                })
        } else {
            if (session.role === 1) {
                const cn = session.customName.split('&');
                if (cn.length > 1) {
                    const customName = cn[0].split('=')[1];
                    session.customName = customName;
                }
            }

            // if exists Additional Session 
            if (this.props.additionalSessions) {
                const index = this.props.additionalSessions.findIndex(elem => { return elem.sessionId === session.id });

                if (index > -1) {
                    if (this.props.additionalSessions[index].name && this.props.additionalSessions[index].name.length > 0) {
                        session.customName = this.props.additionalSessions[index].name;
                    }
                    session.note = this.props.additionalSessions[index].note;
                    session.imageUrl = this.props.additionalSessions[index].avatar;
                }
            }

            this.props.addSession(session);
        }
    }

    toggleLiveConference = (e) => {
        if (e.target.checked) {
            console.log('LIVE CONFERENCE dialIn ' + this.props.dialInNumber + ' hostCode ' + this.props.hostAccessCode);

            this.props.socketIO.emit('login', this.props.dialInNumber, this.props.hostAccessCode);
        }
        else {
            this.setState({
                popupTitle: 'Confirm operation',
                popupMessage: `Are you sure you want to drop all callers?`,
                modal: true,
            })
        }
    }

    fetchRepository = () => {
        if (this.props.conferenceNumber <= 0) return;
        axios.get(`${Config.apiServerUrl}/repository/getFiles/${this.props.conferenceNumber}`)
            .then(res => {
                this.props.clearRepoFiles();
                this.props.addRepoFiles(res.data.response);
            })
            .catch(err => {
                if (err.response && err.response.status === 404) return;
                console.log('Aside fetchRepo', err);
            })
    }

    fetchRecordings = () => {
        RestRequest.getRecordings(this.props.conferenceNumber)
            .then(data => {
                // console.log("Recordings", data);
                this.props.addRecordingsFiles(data.mdrs);
            })
            .catch(e => {
                console.log('fetchRecordings CATCH', e);
            })
    }

    dropAllCallers = () => {
        this.props.setConferenceOff();

        RestRequest.deleteMeeting(this.props.conferenceNumber)
            .then(() => {
                this.props.setConferenceOff();
                this.props.clearSession();
                this.props.clearActiveSpeaker();
                this.props.clearAddSessions();
                this.props.clearRepoFiles();
                this.props.clearRecordingsFiles();
            })
            .catch(e => {
                console.log('dropAllCallers CATCH', e);
            })
        this.modalToggle();
    }

    toggleRecording = (e) => {
        if (e.target.checked) {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "isRecording": true })
                .then(() => {
                    this.props.setRecOn();
                })
                .catch(e => {
                    console.log('toggleRecording CATCH', e);
                })
        }
        else {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "isRecording": false })
                .then(() => {
                    this.props.setRecOff();
                })
                .catch(e => {
                    console.log('toggleRecording CATCH', e);
                })
        }
    }

    toggleHold = (e) => {
        if (e.target.checked) {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "holdMode": 40 })
                .then(() => {
                    this.props.setHoldOn();
                })
                .catch(e => {
                    console.log('toggleHold CATCH', e);
                })
        }
        else {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "holdMode": 0 })
                .then(() => {
                    this.props.setHoldOff();
                })
                .catch(e => {
                    console.log('toggleHold CATCH', e);
                })
        }
    }

    toggleConferenceLock = (e) => {
        if (e.target.checked) {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "isSecured": true })
                .then(() => {
                    this.props.setLockOn();
                })
                .catch(e => {
                    console.log('toggleConferenceLock CATCH', e);
                })
        }
        else {
            RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "isSecured": false })
                .then(() => {
                    this.props.setLockOff();
                })
                .catch(e => {
                    console.log('toggleConferenceLock CATCH', e);
                })
        }
    }

    toggleMuteMode = (param) => {
        if (this.props.qaMode === true) {
            this.props.setMuteLecture();
            this.props.setMuteControl(false);
        }
        else {
            switch (param) {
                case 0:
                    RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "muteMode": 32 })
                        .then(() => {
                            this.props.setMuteOff();
                        })
                        .catch(e => {
                            console.log('toggleMuteMode CATCH', e);
                        })
                    break;

                case 1:
                    RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "muteMode": 36 })
                        .then(() => {
                            this.props.setMuteOff();
                        })
                        .catch(e => {
                            console.log('toggleMuteMode CATCH', e);
                        })
                    break;

                case 2:
                    RestRequest.sendMeetingCommand(this.props.conferenceNumber, { "muteMode": 40 })
                        .then(() => {
                            this.props.setMuteOff();
                        })
                        .catch(e => {
                            console.log('toggleMuteMode CATCH', e);
                        })
                    break;
                default: break;
            }
            this.props.setMuteControl(true);
        }
    }

    setParticipantMuteMode = (param) => {
        console.log("Mute", this.props.callId, param);
        RestRequest.sendCallCommand(this.props.callId, { "muteMode": param })
            .then(() => {
                this.setState({
                    isMuted: param === 1,
                })
            })
            .catch(e => {
                console.log('toggleParticipantMuteMode CATCH', e);
            })
    }

    toggleGraphicMode = (e) => {
        if (e.target.checked) {
            this.props.setGraphicMode(true);
        }
        else {
            this.props.setGraphicMode(false);
        }
    }

    findActiveSession = () => {
        //console.log('findActiveSession', this.props.asideSessionId);

        if (this.props.asideSessionId) {
            const indexSession = this.props.sessions.findIndex(elem => { return elem.id === this.props.asideSessionId });
            if (indexSession > -1) {
                const session = this.props.sessions[indexSession];
                if (session.typeData === 0) {
                    const index = this.props.additionalSessions.findIndex(elem => { return elem.id === this.props.asideSessionId });
                    if (index > -1) {
                        session.customName = this.props.additionalSessions[index].name;
                        session.note = this.props.additionalSessions[index].note;
                        session.imageUrl = this.props.additionalSessions[index].avatar;
                    }
                }
                return session;
            }
        }
    }

    saveProfile = (userProfile) => {
        this.props.updateSession(userProfile);
    }

    infoToast = (msg) => {
        return toast.info(msg, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            newestOnTop: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    }

    renderLoader() {
        return (
            <div style={{ zIndex: '1000', position: 'fixed', top: '50%', left: '50%' }}>
                <HashLoader color={'#6BC8FA'} loading={this.state.loaders} />
            </div>
        );
    }

    async loadParticipant() {
        console.log('Conference number', this.props.conferenceNumber);
        if (!this.props.conferenceNumber) return;

        this.setState({
            loaders: true,
        })

        try {
            const meeting = await RestRequest.getMeetings(this.props.conferenceNumber).catch(e => e);
            console.log('Meeting', meeting);
            this.props.setConferenceOn(meeting);
        } catch (error) {
            console.warn('getMeetings error', error);
        }


        var meetingAttributes = {};
        (meeting.attributes || []).forEach(el => {
            meetingAttributes[el.name] = el.value;
        });
        console.log('Meeting attrs', meetingAttributes);

        try {
            await this.updateParticipantStatus();
        } catch (error) {
            console.warn('updateParticipantStatus error', error);
        }

        this.setState({
            meetingAttributes: meetingAttributes,
            loaders: false,
        })
    }

    async updateParticipantStatus() {
        if (!this.props.isModerator && !this.props.participantPhoneNumber) return;

        const calls = await RestRequest.getCalls(this.props.conferenceNumber).catch(e => e);
        // console.log('Calls', calls);

        let isInTheCall = false;

        if (calls.total > 0) {
            this.props.clearSession();

            calls.calls.sort((a, b) => {
                return b.duration - a.duration;
            })
            calls.calls.forEach(session => {
                // If is a Dialout call switch callee with caller
                session.dialout = this.child && this.child.isPhoneCalled(session.callee);
                session.phoneNumber = session.caller;
                if (session.dialout) {
                    session.phoneNumber = session.callee;
                    session.callee = session.caller;
                    session.caller = session.phoneNumber;
                }

                isInTheCall |= session.caller === this.props.participantPhoneNumber;
                this.getUserProfile(session);
            })
        }

        if (!isInTheCall) {
            this.props.showAlert("Your call has been dropped, logging out.", "warning");
            setTimeout(function () {
                this.props.hideAlert();
            }.bind(this), 5000);
            this.props.logout();
            return;
        }

        setTimeout(function () {
            this.updateParticipantStatus();
        }.bind(this), 30000);
    }


    handleChangeSessionId(e) {
        this.setState({
            webRTCSessionId: e.target.value,
        });
    }

    handleChangeUserName(e) {
        this.setState({
            webRTCUserName: e.target.value,
        });
    }

    startWebRTCSession(event) {
        event.preventDefault();
        // console.log("starting web session", this.state.webRTCSessionName, this.state.webRTCUserName);
        if (this.state.webRTCSessionName && this.state.webRTCUserName) {
            webRTCRequest.getToken(this.state.webRTCSessionName).then((ret) => {
                console.log("this.props.addWebRTCSession", ret, ret.token);
                this.props.addWebRTCSession({
                    token: ret.token,
                    session: true,
                    sessionId: this.state.webRTCSessionName,
                    username: this.state.webRTCUserName
                });
            });
        }
    }

    closeWebRTCSession(event) {
        event.preventDefault();
        // webRTCRequest.closeSession(this.props.webRTCSession);
    }

    /**
     * @deprecated
     */
    handlerJoinSessionEvent = () => {
        console.log("handlerJoinSessionEvent");
    }
    /**
     * @deprecated
     */
    handlerLeaveSessionEvent = () => {
        console.log("handlerLeaveSessionEvent");
        this.props.stopWebRTCSession();
    }
    /**
     * @deprecated
     */
    handlerErrorEvent = (e) => {
        console.log("handlerErrorEvent", e);
    }

    async componentDidMount() {
        console.log('Aside DidMount');
        // console.log(this.props); // TODO remove this line

        window.addEventListener("unload", this.onUnload);
        window.addEventListener("beforeunload", this.onBeforeUnload);
        window.addEventListener("scroll", this.onScrollHandler = (e => { this.onScroll(e) }), { passive: true });

        // if (document.body.innerHTML.indexOf("cdn.voxbone.com/click2vox/click2vox-2") < 0) {
        const script = document.createElement("script");
        script.src = 'https://cdn.voxbone.com/click2vox/click2vox-2.min.js';
        script.async = "async";
        document.body.appendChild(script);
        // }

        if (!this.props.isModerator) {
            await this.loadParticipant();
        }

        this.props.setActiveTab(this.props.isModerator ? '2' : '1');
        this.fetchRepository();

        this.setupBridgeSocket();

    }

    setupBridgeSocket() {
        if (this.bridgeSocketReconnection > 5) return;
        this.bridgeSocketReconnection++;

        console.log("=== BRIDGE SOCKET CREATION ===", this.bridgeSocketReconnection);

        // Create SocketIO connect for application 
        const socket = socketIOClient(`${Config.sipServerUrl}`, {
            timeout: 5000,
            reconnection: true,
            reconnectionAttempts: 20,
        });
        this.props.addSocketIO(socket);

        socket.on("loginemit", async (_data) => {
            var datas = _data.split("\n");
            console.log('TELNET', datas);

            datas.forEach(data => {
                if ((data || '').length === 0) return;

                const dataTokens = data.split(' ');
                const sessionId = dataTokens.length > 1 ? dataTokens[1] : "";

                if (data.indexOf('SELF-ID') > -1) {
                    this.setState({
                        loaders: true,
                    })
                    // Salvo l'ID della mia nuova sessione
                    console.log('SELF-ID SessionId', sessionId);

                    this.props.addSessionId(sessionId.replace('\n', ''));

                    this.idInterval = setInterval(() => {
                        RestRequest.getCalls(this.props.conferenceNumber)
                            .then((res) => {
                                res.calls.forEach(session => {
                                    this.props.updateSessionDuration(session.id, session.duration);
                                });
                            })
                            .catch(e => {
                                console.log('UpdateSessionDuration', e);
                            })
                    }, 15000);

                    if (this.props.conferenceNumber > 0) {
                        this.fetchRepository();
                        this.fetchRecordings();
                    }

                    const id = setInterval(async () => {
                        clearInterval(id);

                        console.log('Conference number', this.props.conferenceNumber);
                        if (!this.props.conferenceNumber) return;

                        const meeting = await RestRequest.getMeetings(this.props.conferenceNumber).catch(e => e);
                        console.log('Meeting', meeting);
                        this.props.setConferenceOn(meeting);

                        var meetingAttributes = {};
                        (meeting.attributes || []).forEach(el => {
                            meetingAttributes[el.name] = el.value;
                        });
                        console.log('Meeting attrs', meetingAttributes);
                        this.setState({
                            meetingAttributes: meetingAttributes,
                        });

                        if (this.props.qaMode === true) {
                            this.props.setMuteLecture();
                            this.props.setMuteControl(false);
                        }

                        const calls = await RestRequest.getCalls(this.props.conferenceNumber).catch(e => e);
                        console.log('Calls', calls);

                        if (calls.total > 0) {
                            this.props.clearSession();
                            this.props.clearQAqueue();
                            this.props.clearActiveSpeaker();

                            calls.calls.sort((a, b) => {
                                return b.duration - a.duration;
                            })
                            calls.calls.forEach(session => {
                                // If is a Dialout call switch callee with caller
                                session.dialout = this.child && this.child.isPhoneCalled(session.callee);
                                session.phoneNumber = session.caller;
                                if (session.dialout) {
                                    session.phoneNumber = session.callee;
                                    session.callee = session.caller;
                                    session.caller = session.phoneNumber;
                                }

                                this.getUserProfile(session);
                                if (session.qaMode > 0) {
                                    this.props.addQAqueue({
                                        sessionId: session.id,
                                        caller: session.caller,
                                        isMuted: session.muteMode,
                                        qaStatus: session.qaMode
                                    });
                                }
                            })
                        }

                    }, 1500);

                    this.setState({
                        loaders: false,
                    });
                }

                if (data.indexOf('NOTIFY-TERMINATION') > -1) {
                    if (this.props.sessionId && this.props.sessionId === sessionId) {
                        clearInterval(this.idInterval);
                        this.props.clearSession();
                        this.props.clearQAqueue();
                        this.props.setConferenceOff();
                        this.props.clearActiveSpeaker();
                        this.props.clearRepoFiles();
                        this.props.clearRecordingsFiles();
                    }
                }

                if (data.indexOf('NOTIFY-DROP') > -1) {
                    /*
                    const i = data.lastIndexOf("NOTIFY-DROP ");
                    let f;
                    if (data.indexOf("BRIDGE") > -1) {
                        f = data.lastIndexOf(" BRIDGE");
                    } else {
                        f = data.lastIndexOf(" USER");
                    }
    
                    const _sessionId = data.substring(i + 12, f);
                    */
                    this.props.deleteSession(sessionId);
                    this.props.delQAqueue(sessionId);
                    this.props.deleteActiveSpeaker(sessionId);
                }

                if (data.indexOf('NOTIFY-SET_CUSTOMNAME') > -1) {
                    RestRequest.getCall(sessionId)
                        .then((res) => {
                            this.props.updateSessionAll(res)
                        })
                        .catch(e => {
                            console.log('NOTIFY-SET_CUSTOMNAME (CATCH)', e);
                        })
                }




                /*
                    *************** RIFARE SENZA CLEAR SESSION !!! ***********************
                */
                /*
                if (data.indexOf('NOTIFY-JOIN') > -1 || data.indexOf('NOTIFY-MUTE') > -1 || data.indexOf('NOTIFY-HOLD') > -1 || data.indexOf('NOTIFY-QA_STATUS') > -1) {      
                    RestRequest.getCalls(this.props.conferenceNumber)
                    .then((res) => {      
                        this.props.clearSession();
                        this.props.clearQAqueue();
                        if (data.indexOf(`NOTIFY-JOIN ${this.props.sessionId} Ctrl`) > -1) {                        
                            const startSearch = data.indexOf(`NOTIFY-JOIN ${this.props.sessionId} Ctrl`) + `NOTIFY-JOIN ${this.props.sessionId} Ctrl`.length;           
                            const key = data.substring(startSearch, startSearch + 5)       
                            this.props.addAudioKey(key.trim().split(' ')[0]);
                        }          
                        res.calls.map(session => {                                                                 
                            this.getUserProfile(session);
                            if(session.qaMode > 0) {
                                this.props.addQAqueue({ 
                                                        sessionId: session.id,
                                                        caller: session.caller,
                                                        isMuted: session.muteMode,
                                                        qaStatus: session.qaMode
                                                    });
                            }
                        });                         
                    })
                    .catch(e => {
                        console.log('getCalls', e);
                    })                
                }
                */

                // NUOVA PROCEDURA
                if (data.indexOf('NOTIFY-JOIN') > -1) {
                    RestRequest.getCall(sessionId)
                        .then((session) => {
                            // If is a Dialout call switch callee with caller
                            session.dialout = this.child && this.child.isPhoneCalled(session.callee);
                            session.phoneNumber = session.caller;
                            if (session.dialout) {
                                session.phoneNumber = session.callee;
                                session.callee = session.caller;
                                session.caller = session.phoneNumber;
                            }

                            this.getUserProfile(session);

                            if (session.qaMode > 0) {
                                this.props.addQAqueue({
                                    sessionId: session.id,
                                    caller: session.phoneNumber,
                                    isMuted: session.muteMode,
                                    qaStatus: session.qaMode
                                });
                            }
                        })
                        .catch(e => {
                            console.log('Added new session (CATCH)', e);
                        })
                }

                if (data.indexOf('NOTIFY-MUTE') > -1 || data.indexOf('NOTIFY-HOLD') > -1 || data.indexOf('NOTIFY-QA_STATUS') > -1) {
                    let index = 0;
                    if (data.indexOf('NOTIFY-MUTE') > -1) index = 5;
                    if (data.indexOf('NOTIFY-HOLD') > -1) index = 4;
                    if (data.indexOf('NOTIFY-QA_STATUS') > -1) index = 1;

                    const _sessionId = data.split(' ')[index];
                    RestRequest.getCall(_sessionId)
                        .then((session) => {
                            // If is a Dialout call switch callee with caller
                            session.dialout = this.child && this.child.isPhoneCalled(session.callee);
                            session.phoneNumber = session.caller;
                            if (session.dialout) {
                                session.phoneNumber = session.callee;
                                session.callee = session.caller;
                                session.caller = session.phoneNumber;
                            }

                            this.props.updateSessionAll(session);
                            
                            if (session.qaMode > 0) {
                                this.props.addQAqueue({
                                    sessionId: session.id,
                                    caller: session.caller,
                                    isMuted: session.muteMode,
                                    qaStatus: session.qaMode
                                });
                            }
                            else {
                                this.props.delQAqueue(session.id);
                            }
                        })
                        .catch(e => {
                            console.log('Update session (CATCH)', e);
                        })
                }
                // END NUOVA PROCEDURA





                if (data.indexOf('NOTIFY-RECORDING') !== -1) {
                    if (data.split(' ')[1] === 'True') {
                        this.props.setRecOn();
                    } else {
                        this.props.setRecOff();
                    }
                }

                if (data.indexOf('NOTIFY-LOCK') !== -1) {
                    if (data.split(' ')[1] === 'True') {
                        this.props.setLockOn();
                    } else {
                        this.props.setLockOff();
                    }
                }

                if (data.indexOf('NOTIFY-QA_MODE') !== -1) {
                    if (data.split(' ')[1] === 'True') {
                        this.props.setQAmode(true)
                        this.props.setMuteLecture();
                        this.props.setMuteControl(false);
                    } else {
                        this.props.setQAmode(false)
                        this.props.setMuteOff();
                        this.props.setMuteControl(true);
                        this.props.clearQAqueue();
                    }
                }

                if (data.indexOf('AS ') !== -1) {
                    const lists = data.split(',');
                    lists.forEach((item, index) => {
                        const _sessionId = parseInt(item.split(' ')[1], 10);
                        const _volume = parseInt(item.split(' ')[2] || '0', 10);

                        if (_sessionId !== '268435455') {
                            if (!this.state.idIntervalAS) {
                                const idIntervalAS = setInterval(() => {
                                    this.props.listActiveSpeaker.forEach((as) => {
                                        if (as.volume < 3) {
                                            this.props.deleteActiveSpeaker(as.sessionId);
                                        }
                                    })
                                    clearInterval(idIntervalAS);
                                    this.setState({
                                        idIntervalAS: null
                                    })
                                    console.log('CLEAR INTERVAL', idIntervalAS);
                                }, 20000);

                                this.setState({
                                    idIntervalAS
                                })
                                console.log('SET INTERVAL', idIntervalAS);
                            }

                            const _session = this.props.sessions.find(session => { return session.id === _sessionId });
                            // console.log("setActiveSpeaker", _session, this.props.sessions);
                            const callerName = _session ? _session.customName || (_session.caller.indexOf("anonymous") >= 0 ? "anonymous #" + _sessionId : _session.caller) : _sessionId;
                            if (_volume > 0) {
                                this.props.setActiveSpeaker({
                                    sessionId: _sessionId,
                                    caller: callerName,
                                    volume: _volume
                                });
                            }
                        } else {
                        }
                    });
                }

                if (data.indexOf('NOTIFY-GROUP Speaker') !== -1) {
                    if (data.split(' ')[5] === 'True') {
                        this.props.setHoldOn();
                    }
                    else {
                        this.props.setHoldOff();
                    }
                    switch (data.split(' ')[3]) {
                        case 'False':
                            this.props.setMuteOff();
                            break;
                        case 'Relaxed':
                            this.props.setMuteMute();
                            break;
                        case 'Strict':
                            this.props.setMuteLecture();
                            break;
                        default:
                            break;
                    }
                }
            });
        });

        socket.on('addfileemit', (data) => {
            this.infoToast('Added new file... ');
            this.props.clearRepoFiles();
            this.fetchRepository();
        });

        socket.on('delfileemit', (data) => {
            this.infoToast('Deleted file... ');
            this.props.clearRepoFiles();
            this.fetchRepository();
        });

        socket.on('disconnect', (data) => {
            this.infoToast('Disconnected... ');
            console.log('Disconnected');
            setTimeout(() => {
                this.forceUpdate(() => {
                    if (this.props.liveConference) {
                        this.setupBridgeSocket();
                        this.props.socketIO.disconnect();
                        console.log('REPEAT LIVE CONFERENCE dialIn ' + this.props.dialInNumber + ' hostCode ' + this.props.hostAccessCode);

                        this.props.socketIO.emit('login', this.props.dialInNumber, this.props.hostAccessCode);
                    }
                })
            }, 2000);
        });
    }

    componentWillUnmount() {
        if (this.idInterval)
            clearInterval(this.idInterval);

        window.removeEventListener("beforeunload", this.onBeforeUnload);
        window.removeEventListener("scroll", this.onScrollHandler);

        if (this.state.idIntervalAS) {
            clearInterval(this.state.idIntervalAS);
        }

        if (this.props.socketIO) {
            this.props.socketIO.disconnect();
            this.props.delSocketIO();
        }

    }

    toggleDialinModal = () => {
        this.setState({
            dialinModal: !this.state.dialinModal
        });
    }

    render() {
        if (!this.props.isModerator) {
            return this.renderParticipant();
        }

        return (
            <aside className="aside-menu" ref={this.elementRef}>
                {this.renderLoader()}

                <ToastContainer
                    position="bottom-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Modal isOpen={this.state.modal} toggle={this.modalToggle} className="modal-danger">
                    <ModalHeader toggle={this.modalToggle}>
                        {this.state.popupTitle}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.popupMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.dropAllCallers}>Yes</Button>{' '}
                        <Button color="secondary" onClick={this.modalToggle}>No</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.dialinModal} toggle={this.toggleDialinModal} id="modalIFrame" className="modal-lg">
                    <ModalHeader toggle={this.toggleDialinModal}>Dial-In Numbers</ModalHeader>
                    <ModalBody>
                        <iframe title={this.props.conferenceNumber} src={"https://portal.joinconferencing.com/webapi/dnis/cid/" + this.props.conferenceNumber}></iframe>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleDialinModal}>Close</Button>
                    </ModalFooter>
                </Modal>

                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.props.asideActiveTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <i className="icon-info"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.props.asideActiveTab === '2' })} onClick={() => { this.toggle('2'); }}>
                            <i className="icon-pencil"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.props.asideActiveTab === '4' })} onClick={() => { this.toggle('4'); }}>
                            <i className="icon-earphones-alt"></i>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.props.asideActiveTab}>
                    <TabPane tabId="1">
                        <div className="callout callout-warning m-0 py-3">
                            <div>Dial-in Number</div>
                            { /* <small className="text-muted mr-3">{this.props.dialInNumber}</small> */}
                            <small><a href="#dialin" onClick={(e) => { e.preventDefault(); this.toggleDialinModal(); }}><FontAwesomeIcon icon={faAddressBook} /> Browse phone numbers</a></small>
                        </div>
                        <hr className="mx-3 my-0" />
                        <DialoutItem childRef={ref => (this.child = ref)} className="callout callout-success m-0 py-3" />
                        {this.props.hostAccessCode ? <hr className="mx-3 my-0" /> : null}
                        {this.props.hostAccessCode ?
                            <div className="callout callout-info m-0 py-3">
                                <div>Host Code</div>
                                <small className="text-muted mr-3">{this.props.hostAccessCode}</small>
                            </div>
                            : null
                        }
                        {this.props.participantAccessCode ? <hr className="mx-3 my-0" /> : null}
                        {this.props.participantAccessCode ?
                            <div className="callout callout-danger m-0 py-3">
                                <div>Participant Code</div>
                                <small className="text-muted mr-3">{this.props.participantAccessCode}</small>
                            </div>
                            : null
                        }
                        { /*
                        <hr className="mx-3 my-0" />
                        <div className="callout callout-success m-0 py-3">
                            <div>Invite Others</div>
                            <small className="text-muted mr-3"><FontAwesomeIcon icon={faCopy} /></small>
                            <small className="text-muted mr-3"><FontAwesomeIcon icon={faPhone} /></small>
                        </div>
                        */ }
                        <hr className="mx-3 my-0" />
                        { /* 
                        <div className="callout callout-primary m-0 py-3">
                            <div>Playback number</div>
                            <small className="text-muted mr-3">{this.props.playBackNumber}</small>
                        </div>
                        <hr className="mx-3 my-0" />
                        */}
                    </TabPane>

                    <TabPane tabId="2" className="p-3">
                        <h6>Options</h6>
                        <div className="aside-options">
                            <div className="clearfix mb-4">
                                <small><b>Graphic mode</b></small>
                                <Label className="switch switch-text switch switch-primary switch-sm float-right">
                                    <Input type="checkbox" className="switch-input" onChange={this.toggleGraphicMode} checked={this.props.isGraphicMode} />
                                    <span className="switch-label" data-on="On" data-off="Off"></span>
                                    <span className="switch-handle"></span>
                                </Label>
                            </div>
                        </div>

                        <h6>Settings</h6>
                        <div className="aside-options">
                            <div className="clearfix mt-2">
                                <small><b>Live Conference</b></small>
                                <Label className="switch switch-text switch switch-primary switch-sm float-right">
                                    <Input type="checkbox" className="switch-input" onChange={this.toggleLiveConference} checked={this.props.liveConference} disabled={this.state.loaders ? true : false} />
                                    <span className="switch-label" data-on="On" data-off="Off"></span>
                                    <span className="switch-handle"></span>
                                </Label>
                            </div>
                        </div>

                        {this.props.liveConference ?
                            <div>
                                <div className="aside-options">
                                    <div className="clearfix mt-3">
                                        <small className={this.detectRecordableFlag && this.state.meetingAttributes.conference_recording !== "on" ? "text-gray-300" : ""}><b>Recording</b></small>
                                        <Label className="switch switch-text switch switch-primary switch-sm float-right">
                                            <Input id="ckbRecording" type="checkbox" className="switch-input" onChange={this.toggleRecording} checked={this.props.isRecording} disabled={this.detectRecordableFlag && this.state.meetingAttributes.conference_recording !== "on"} />
                                            <span className="switch-label" data-on="On" data-off="Off"></span>
                                            <span className="switch-handle"></span>
                                        </Label>
                                    </div>
                                </div>

                                <div className="aside-options">
                                    <div className="clearfix mt-3">
                                        <small><b>Hold</b></small>
                                        <Label className="switch switch-text switch switch-primary switch-sm float-right">
                                            <Input id="ckbHold" type="checkbox" className="switch-input" onChange={this.toggleHold} checked={this.props.isOnHold} />
                                            <span className="switch-label" data-on="On" data-off="Off"></span>
                                            <span className="switch-handle"></span>
                                        </Label>
                                    </div>
                                </div>

                                <div className="aside-options">
                                    <div className="clearfix mt-3">
                                        <small><b>Secure</b></small>
                                        <Label className="switch switch-text switch switch-primary switch-sm float-right">
                                            <Input id="ckbConfLock" type="checkbox" className="switch-input" onChange={this.toggleConferenceLock} checked={this.props.isSecured} />
                                            <span className="switch-label" data-on="On" data-off="Off"></span>
                                            <span className="switch-handle"></span>
                                        </Label>
                                    </div>
                                </div>

                                <div className="aside-options">
                                    <div className="clearfix mt-3">
                                        <small><b>Mute Mode</b></small>
                                        <ButtonGroup>
                                            <Button color={this.props.muteMode === 0 ? "primary" : "secondary"} onClick={() => this.toggleMuteMode(0)} disabled={this.props.muteControlEnabled === false} >Off</Button>
                                            <Button color={this.props.muteMode === 1 ? "primary" : "secondary"} onClick={() => this.toggleMuteMode(1)} disabled={this.props.muteControlEnabled === false} >Mute</Button>
                                            <Button color={this.props.muteMode === 2 ? "primary" : "secondary"} onClick={() => this.toggleMuteMode(2)} disabled={this.props.muteControlEnabled === false} >Lecture</Button>
                                        </ButtonGroup>
                                    </div>
                                    <div>
                                        {this.props.muteControlEnabled === false ? <p>To change Mute Mode again please end your Q&A session first</p> : null}
                                    </div>
                                </div>

                                <div className="aside-options" style={{ visibility: "hidden" }}>
                                    <div className="clearfix mt-5" style={{ color: '#ff0000' }}>
                                        <FontAwesomeIcon icon={faKey} /> Audio key<br />
                                        Press #9{this.props.audioKey}
                                    </div>sssss
                                </div>
                            </div>
                            :
                            null
                        }

                    </TabPane>
                    <TabPane tabId="3" className="p-3">
                        <InfoParticipant conferenceNumber={this.props.conferenceNumber} session={this.findActiveSession()} onSave={this.saveProfile} />
                    </TabPane>

                    <TabPane tabId="4" className="p-3">
                        <div className="voxButton" data-text="Call%20Now"
                            data-did="442033181237"
                            data-caller_id="webRTCUser"
                            data-incompatible_browser_configuration="hide_widget"
                            data-server_url="https://www.voxbone.com/click2vox"
                            data-use_default_button_css="true"
                            data-div_css_class_name="style-a"
                            data-basic_auth="true"
                            data-voxbone_webrtc_username="joinconferencing"
                            data-voxbone_webrtc_password="Un1f13d!"
                            data-rating="false"
                            data-show_branding="false"
                            data-placement="bottom-right">
                        </div>

                        <hr />

                        {this.state.enableDataSharing && <DataSharing _state={this.state} _webRTCSession={this.props.webRTCSession} _startWebRTCSession={this.startWebRTCSession} _handleChangeUserName={this.handleChangeUserName} />}
                    </TabPane>
                </TabContent>

                { /* <ScrollUpButton /> */}
            </aside>
        )
    }

    renderParticipant() {
        return (
            <aside className="aside-menu">
                {this.renderLoader()}

                <ToastContainer
                    position="bottom-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Modal isOpen={this.state.modal} toggle={this.modalToggle} className="modal-danger">
                    <ModalHeader toggle={this.modalToggle}>
                        {this.state.popupTitle}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.popupMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.dropAllCallers}>Yes</Button>{' '}
                        <Button color="secondary" onClick={this.modalToggle}>No</Button>
                    </ModalFooter>
                </Modal>

                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.props.asideActiveTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <i className="icon-info"></i>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.props.asideActiveTab === '4' })} onClick={() => { this.toggle('4'); }}>
                            <i className="icon-earphones-alt"></i>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.props.asideActiveTab}>
                    <TabPane tabId="1">
                        {this.props.participantPhoneNumber ?
                            <div className="p-3">
                                <h6>Options</h6>
                                <div className="aside-options">
                                    <div className="clearfix mb-4">
                                        <small><b>Graphic mode</b></small>
                                        <Label className="switch switch-text switch switch-primary switch-sm float-right">
                                            <Input type="checkbox" className="switch-input" onChange={this.toggleGraphicMode} checked={this.props.isGraphicMode} />
                                            <span className="switch-label" data-on="On" data-off="Off"></span>
                                            <span className="switch-handle"></span>
                                        </Label>
                                    </div>

                                    {this.state.isMuted ?
                                        <button type="button" className="btn btn-block btn-danger" onClick={() => this.setParticipantMuteMode(0)}><FontAwesomeIcon icon={faMicrophoneSlash} />&nbsp; Muto</button>
                                        :
                                        <button type="button" className="btn btn-block btn-outline-danger" onClick={() => this.setParticipantMuteMode(1)}><FontAwesomeIcon icon={faMicrophone} />&nbsp; Muto</button>
                                    }
                                </div>
                            </div>
                            : null
                        }

                        {this.props.participantPhoneNumber ? <hr className="mx-3 my-0" /> : null}

                        <div className="callout callout-danger m-0 py-3">
                            <div>Participant Code</div>
                            <small className="text-muted mr-3">{this.props.participantAccessCode}</small>
                        </div>
                        <hr className="mx-3 my-0" />
                    </TabPane>

                    <TabPane tabId="4" className="p-3">
                        <div className="voxButton" data-text="Call%20Now"
                            data-did="442033181237"
                            data-caller_id="webRTCUser"
                            data-incompatible_browser_configuration="hide_widget"
                            data-server_url="https://www.voxbone.com/click2vox"
                            data-use_default_button_css="true"
                            data-div_css_class_name="style-a"
                            data-basic_auth="true"
                            data-voxbone_webrtc_username="joinconferencing"
                            data-voxbone_webrtc_password="Un1f13d!"
                            data-rating="false"
                            data-show_branding="false"
                            data-placement="bottom-right">
                        </div>

                        <hr />

                        {this.state.enableDataSharing && <DataSharing _state={this.state} _webRTCSession={this.props.webRTCSession} _startWebRTCSession={this.startWebRTCSession} _handleChangeUserName={this.handleChangeUserName} />}
                    </TabPane>
                </TabContent>

                { /* <ScrollUpButton /> */}
            </aside>
        )
    }
}

class DataSharing extends Component {
    render() {
        return <Card>
            <CardHeader>
                <FontAwesomeIcon icon={faVideo} /> Data sharing
            </CardHeader>
            {!this.props._webRTCSession ?
                <CardBody className="p-2">
                    <InputGroup className="mb-2">
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text"><FontAwesomeIcon icon={faTag} /></span>
                        </InputGroupAddon>
                        <Input placeholder="Your name" type="text" id="webRTCuserName" defaultValue={this.props._state.webRTCUserName} onChange={(e) => this.props._handleChangeUserName(e)} required />
                    </InputGroup>

                    <Button className="btn-block" disabled={this.props._state.webRTCUserName.length === 0} color="primary" onClick={(e) => this.props._startWebRTCSession(e)}>Start session</Button>
                </CardBody>
                :
                <CardBody>
                    <em>Logged in as <b>{this.props._state.webRTCUserName}</b></em>
                </CardBody>
            }
        </Card>
    }
}

// These props come from the application's
// state when it is started
const mapStateToProps = state => ({
    isModerator: state.authorization.isModerator,
    conferenceNumber: state.authorization.conferenceNumber,
    dialInNumber: state.authorization.dialInNumber,
    playBackNumber: state.authorization.playBackNumber,
    hostAccessCode: state.authorization.hostAccessCode,
    participantAccessCode: state.authorization.participantAccessCode,
    participantPhoneNumber: state.authorization.participantPhoneNumber,
    liveConference: state.conference.liveConference,
    conferenceId: state.conference.conferenceId,
    callId: state.authorization.callId,
    isOnHold: state.conference.holdMode === 0 ? false : true,
    isPolling: state.conference.isPolling,
    isRecording: state.conference.isRecording,
    isSecured: state.conference.isSecured,
    muteMode: state.conference.muteMode,
    muteControlEnabled: state.conference.muteControlEnabled,
    sessionId: state.sessions.sessionId,
    audioKey: state.sessions.audioKey,
    listQAqueue: state.qaqueue.list,
    qaMode: state.conference.qaMode,
    listActiveSpeaker: state.activeSpeaker.list,
    sessions: state.sessions.list,
    asideActiveTab: state.general.asideActiveTab,
    asideSessionId: state.general.asideSessionId,
    isGraphicMode: state.general.isGraphicMode,
    additionalSessions: state.additionalSessions.list,
    socketIO: state.socket.socketIO,
    webRTCSession: state.webRTC.session,
    webRTCSessionId: state.webRTC.sessionId,
    webRTCToken: state.webRTC.token,
    webRTCUsername: state.webRTC.username,
})

const mapDispatchToProps = dispatch => ({
    setConferenceOff: () => dispatch(setConferenceOff()),
    setConferenceOn: (conference) => dispatch(setConferenceOn(conference)),
    setRecOn: () => dispatch(setConferenceRecOn()),
    setRecOff: () => dispatch(setConferenceRecOff()),
    setHoldOn: () => dispatch(setConferenceHoldOn()),
    setHoldOff: () => dispatch(setConferenceHoldOff()),
    setLockOn: () => dispatch(setConferenceLockOn()),
    setLockOff: () => dispatch(setConferenceLockOff()),
    setMuteOff: () => dispatch(setConferenceMuteOff()),
    setMuteMute: () => dispatch(setConferenceMuteMute()),
    setMuteLecture: () => dispatch(setConferenceMuteLecture()),
    setMuteControl: (status) => dispatch(setConferenceMuteControl(status)),
    setQAmode: (status) => dispatch(setQAmode(status)),
    addSession: (session) => dispatch(addSession(session)),
    updateSession: (session) => dispatch(updateSession(session)),
    updateSessionAll: (session) => dispatch(updateSessionAll(session)),
    updateSessionDuration: (sessionId, duration) => dispatch(updateSessionDuration(sessionId, duration)),
    deleteSession: (sessionId) => dispatch(deleteSession(sessionId)),
    countSession: (count) => dispatch(countSession(count)),
    clearSession: () => dispatch(clearSession()),
    addSessionId: (id) => dispatch(addSessionId(id)),
    addAudioKey: (audioKey) => dispatch(addAudioKey(audioKey)),
    addQAqueue: (qaqueue) => dispatch(addQAqueue(qaqueue)),
    delQAqueue: (sessionId) => dispatch(delQAqueue(sessionId)),
    clearQAqueue: () => dispatch(clearQAqueue()),
    setActiveSpeaker: (activeSpeaker) => dispatch(setActiveSpeaker(activeSpeaker)),
    deleteActiveSpeaker: (sessionId) => dispatch(deleteActiveSpeaker(sessionId)),
    clearActiveSpeaker: () => dispatch(clearActiveSpeaker()),
    updateAddSession: (addSession) => dispatch(updateAdditionalSession(addSession)),
    clearAddSessions: () => dispatch(clearAddSessions()),
    setActiveTab: (tab) => dispatch(asideSetActiveTab(tab)),
    setGraphicMode: (isGraphicMode) => dispatch(setGraphicMode(isGraphicMode)),
    addRepoFiles: (files) => dispatch(addRepoFiles(files)),
    clearRepoFiles: () => dispatch(clearRepoFiles()),
    addRecordingsFiles: (files) => dispatch(addRecordingsFiles(files)),
    clearRecordingsFiles: () => dispatch(clearRecordingsFiles()),
    addSocketIO: (socketIO) => dispatch(addSocket(socketIO)),
    delSocketIO: () => dispatch(delSocket()),
    logout: () => dispatch(sendLogout()),
    showAlert: (message, color) => dispatch(showAlert(message, color)),
    hideAlert: () => dispatch(hideAlert()),
    addWebRTCSession: (webRTCSession) => dispatch(addWebRTCSession(webRTCSession)),
    stopWebRTCSession: () => dispatch(stopWebRTCSession()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Aside);
