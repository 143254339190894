import C from '../constants';

const initialState = {
    token: '',
    session: false,
    sessionId: '',
    username: '',
};

const webRTC = (state = initialState, action) => {
    switch (action.type) {
        case C.START_WEBRTC_SESSION:
            console.log("Called WebRTC Reducer - START_WEBRTC_SESSION", state, action);
            state = Object.assign({}, state, {
                token: action.webRTCSession.token,
                session: action.webRTCSession.session || false,
                sessionId: action.webRTCSession.sessionId,
                username: action.webRTCSession.username,
            });
            return state;

        case C.STOP_WEBRTC_SESSION:
            console.log("Called WebRTC Reducer - STOP_WEBRTC_SESSION", state, action);
            state = Object.assign({}, state, initialState);
            return state;

        default:
            return state;
    }
}
export default webRTC;