import axios from 'axios';

const Config = require('../config/config');

const webRTCRequest = {
    getToken: (mySessionId) => {
        console.log("WebRTCRequest -> getToken", mySessionId);
        return webRTCRequest.createSession(mySessionId)
            .then((sessionId) => webRTCRequest.createToken(sessionId))
            .catch((Err) => console.error(Err));
    },

    createSession: (sessionId) => {
        console.log("WebRTCRequest -> createSession", sessionId);
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ customSessionId: sessionId });
            axios
                .post(Config.OPENVIDU_SERVER_URL + '/api/sessions', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + Config.OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('CREATE SESSION', response);
                    resolve(response.data.id);
                })
                .catch((response) => {
                    var error = Object.assign({}, response);
                    if (error.response && error.response.status === 409) {
                        resolve(sessionId);
                    } else {
                        console.log(error);
                        console.warn(
                            'No connection to OpenVidu Server. This may be a certificate error at ' + Config.OPENVIDU_SERVER_URL,
                        );
                        if (
                            window.confirm(
                                'No connection to OpenVidu Server. This may be a certificate error at "' +
                                Config.OPENVIDU_SERVER_URL +
                                '"\n\nClick OK to navigate and accept it. ' +
                                'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                                Config.OPENVIDU_SERVER_URL +
                                '"',
                            )
                        ) {
                            window.location.assign(Config.OPENVIDU_SERVER_URL + '/accept-certificate');
                        }
                    }
                });
        });
    },

    createToken: (sessionId) => {
        console.log("WebRTCRequest", "createToken", sessionId);
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({ session: sessionId });
            axios
                .post(Config.OPENVIDU_SERVER_URL + '/api/tokens', data, {
                    headers: {
                        Authorization: 'Basic ' + btoa('OPENVIDUAPP:' + Config.OPENVIDU_SERVER_SECRET),
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log('TOKEN', response);
                    resolve(response.data);
                })
                .catch((error) => reject(error));
        });
    },

    closeSession: (session) => {
        console.log("WebRTCRequest", "closeSession", session);
        if (session) {
            console.log("WebRTCRequest", "disconnecting -> TO IMPLEMENT");
            // session.disconnect();
        }
    }
};

export default webRTCRequest;