import C from '../constants';

export const setActiveSpeaker = activeSpeaker => ({
    type: C.SET_ACTIVESPEAKER,
    activeSpeaker,
})

export const deleteActiveSpeaker = sessionId => ({
    type: C.DELETE_ACTIVESPEAKER,
    sessionId,
})

export const clearActiveSpeaker = () => ({
    type: C.CLEAR_ACTIVESPEAKER    
})