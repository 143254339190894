import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import store from './redux/store';
import AuthRequired from './lib/Authentication';

import Login from './views/Login';
import Page404 from './views/Page404';
import Page500 from './views/Page500';
import Full from './views/Full';

import './index.css';// Import Main styles for this application
import 'flag-icon-css/css/flag-icon.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './assets/scss/style.scss';
import './assets/scss/core/_dropdown-menu-right.scss';

window.React = React;
window.ReactDOM = ReactDOM;
window.store = store;

const Config = require('./config/config');

if (Config.development) {
  try {
    let sipServerMatch = Config.sipServerUrl.match(/(.*?)\:(.*?)\:([0-9]+)$/);
    let apiServerMatch = Config.apiServerUrl.match(/(.*?)\:(.*?)\:([0-9]+)$/);
    Config.sipServerUrl = `${sipServerMatch[1]}://${window.location.hostname}:${sipServerMatch[3]}`;
    Config.apiServerUrl = `${apiServerMatch[1]}://${window.location.hostname}:${apiServerMatch[3]}`;
    console.log(`Using Config.apiServerUrl = ${Config.apiServerUrl} , Config.sipServerUrl = ${Config.sipServerUrl}`);
  } catch (error) {
    console.warn(`Using Config.apiServerUrl = ${Config.apiServerUrl} , Config.sipServerUrl = ${Config.sipServerUrl}`);
  }
}

ReactDOM.render((
    <Provider store={store}>
        <HashRouter>
            <Switch>
                <Route exact path="/login" name="Login Page" component={Login} />
                <Route exact path="/silent/:id/:hc" name="Login Page" component={Login} />
                { /* <Route exact path="/register" name="Register Page" component={Register} /> */}
                <Route exact path="/404" name="Page 404" component={Page404} />
                <Route exact path="/500" name="Page 500" component={Page500} />
                <Route path="/" name="Conference View" component={AuthRequired(Full)} />
            </Switch>
        </HashRouter>
    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
