import C from '../constants';

const initialState = {
    list: [],
};

const sessions = (state = initialState, action) => {    
    switch (action.type) {
        case C.ADD_QAQUEUE:
            if(state && state.list) {
                const index = state.list.findIndex(session => session.sessionId === action.qaqueue.sessionId); 
                if(index>-1) {
                    return {
                        ...state,
                        list: [
                            ...state.list.slice(0, index),
                            {
                                ...state.list[index],
                                isMuted: action.qaqueue.isMuted,
                                qaStatus: action.qaqueue.qaStatus                        
                            },
                            ...state.list.slice(index+1)
                        ]
                    }
                }
                else {
                    return {                
                        ...state,
                        list: [...state.list, {
                            sessionId: action.qaqueue.sessionId,
                            caller: action.qaqueue.caller,
                            isMuted: action.qaqueue.isMuted,
                            qaStatus: action.qaqueue.qaStatus   
                        }]
                    }
                }
            }
            return state;

            case C.DEL_QAQUEUE:        
                const index = state.list.findIndex(session => session.sessionId === action.sessionId);
                
                if(index > -1) {
                    return {               
                        ...state, 
                        list: [
                            ...state.list.slice(0, index),
                            ...state.list.slice(index + 1)
                        ],                
                    }                
                }
                return state;
            
        case C.CLEAR_QAQUEUE:    
            state = Object.assign({}, state, initialState);
            return state;

        default:
            return state;
    }
}
export default sessions;