import React, { Component } from 'react';
import {
    Form, FormGroup,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Label,
    Input,
    Button
} from 'reactstrap';

import UploadImage from '../UploadImage';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

// Redux
import { connect } from 'react-redux'
import { updateAdditionalSession } from '../../redux/actions/AdditionalSessionAction';
import { asideSetActiveTab } from '../../redux/actions/GeneralAction';

const Config = require('../../config/config');

class InfoParticipant extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
        };

        this.onImageChange = this.onImageChange.bind(this);
        this.onUploadImageChange = this.onUploadImageChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
    }

    modalToggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }


    onImageChange(event) {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.setState({
                    image: e.target.result,
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    onUploadImageChange(data) {
        this.setState({
            imageUpload: data
        })
    }


    onTextChange(e) {
        const state = this.state;
        state[e.target.id] = e.target.value;
        this.setState(state);
    }


    undoProfile = () => {
        this.setState({
            id: null,
            image: null,
            name: null,
            note: null,
            avatar: 'img/conference/avatars/0.png',
            typeData: 0
        });
        this.props.setActiveTab('2');
    }

    deleteProfile = () => {
        const af = this.props.session.addressFrom;
        const address = af.substring(af.lastIndexOf("sip:") + 4, af.lastIndexOf("@"));

        const inputFile = document.getElementById('inputFile');

        axios.delete(`${Config.apiServerUrl}/users/deleteProfileForRoom/${this.props.conferenceNumber}/${address}`)
            .then(res => {
                inputFile.value = '';

                this.setState({
                    modal: false,
                    image: null,
                    name: null,
                    note: null,
                    id: null,
                    callerPhone: false
                });
                this.props.setActiveTab('2');
            })
            .catch(err => {
                console.log('InfoParticipant deleteProfile (CATCH)', err);
            })

    }

    saveProfile = () => {
        console.log('InfoParticipant (saveProfile)', this.props.session);

        if (!this.props.session || !this.props.session.id) {
            this.props.setActiveTab('2');
        }

        let userProfile = {
            id: this.props.session.id,
            addressFrom: '',
            name: '',
            note: '',
            typeData: 0
        };

        const inputFile = document.getElementById('inputFile');

        userProfile.name = this.state.name;
        userProfile.note = this.state.note;

        userProfile.avatar = this.state.image || this.state.avatar;

        if (this.state.typeData === 2) {
            userProfile.typeData = 2;

            const af = this.props.session.addressFrom;
            const address = af.substring(af.lastIndexOf("sip:") + 4, af.lastIndexOf("@"));

            axios.post(`${Config.apiServerUrl}/users/updateProfileForRoom/${this.props.conferenceNumber}/${address}`, userProfile)
                .then(res => {
                    inputFile.value = '';

                    this.setState({
                        modal: false,
                        image: null,
                        name: null,
                        note: null,
                        id: null,
                        callerPhone: false
                    });
                    this.props.setActiveTab('2');
                    this.props.onSave(userProfile);
                })
                .catch(err => {
                    console.log('InfoParticipant saveProfile (CATCH)', err);
                })
        }
        else {
            this.props.updateAddSession(userProfile);
            inputFile.value = '';

            this.setState({
                image: null,
                name: null,
                note: null,
                id: null,
                callerPhone: false
            });
            this.props.setActiveTab('2');
            this.props.onSave(userProfile);
        }
    }

    toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))

    componentDidUpdate(next) {
        console.log('InfoParticipant componentDidUpdate', next);

        if (next.session) {
            this.toDataURL(next.session.imageUrl)
                .then(dataUrl => {
                    this.setState({
                        id: next.session.id,
                        addressFrom: next.session.addressFrom,
                        name: next.session.customName,
                        note: next.session.note,
                        avatar: dataUrl,
                        typeData: next.session.typeData
                    });
                })

        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.modalToggle} className="modal-content" size="lg">
                    <ModalHeader toggle={this.modalToggle}>Edit image</ModalHeader>
                    <ModalBody>
                        <UploadImage name="deviceimage" onUploadImageChange={(data) => this.onUploadImageChange(data)} image={this.state.avatar} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => {
                            this.setState({
                                image: this.state.imageUpload,
                                modal: false
                            });
                        }}
                        >Save</Button>{' '}
                        <Button color="secondary" onClick={this.modalToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <h6>Edit user profile</h6>

                <div className="aside-profile-pic">
                    <img alt="avatar" id="target" className="aside-content-image" src={this.state.image ? this.state.image : this.state.avatar} />
                    <div className="aside-edit">
                        {/* <a href="#" onClick={(e)=>{e.preventDefault(); document.getElementById('inputFile').click(); }}><i className="fa fa-pencil fa-lg"></i></a> */}
                        <a href="#edit" onClick={(e) => { e.preventDefault(); this.setState({ modal: true }); }} className="ml-1">
                            <FontAwesomeIcon icon={ faPencilAlt } size="lg" />
                        </a>
                    </div>
                </div>
                <input id="inputFile" type="file" hidden onChange={this.onImageChange} />
                <Form>
                    <FormGroup>
                        <Label for="profileName">Name</Label>
                        <Input type="text" name="name" id="name" placeholder="edit profile name" onChange={(e) => this.onTextChange(e)} value={this.state.name} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="profileNote">Note</Label>
                        <Input type="textarea" name="note" id="note" onChange={(e) => this.onTextChange(e)} value={this.state.note} />
                    </FormGroup>

                    <Button block color="primary" type="button" onClick={this.saveProfile}>{this.state.typeData === 0 ? 'Save in session' : 'Save in room'}</Button>{' '}
                    <Button block color="warning" type="button" onClick={this.undoProfile}>Undo</Button>
                    {this.state.typeData === 2 ? <Button block color="danger" type="button" onClick={this.deleteProfile}>Delete</Button> : null}
                </Form>

            </div>
        )
    }
}

// These props come from the application's
// state when it is started  
const mapDispatchToProps = dispatch => ({
    updateAddSession: (addSession) => dispatch(updateAdditionalSession(addSession)),
    setActiveTab: (tab) => dispatch(asideSetActiveTab(tab)),
})

export default connect(null, mapDispatchToProps)(InfoParticipant);