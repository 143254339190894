import C from '../constants';

const initialState = {
    list: [],
};

const sessions = (state = initialState, action) => {
    switch (action.type) {
        case C.SET_ACTIVESPEAKER:
            const index = state.list.findIndex(session => session.sessionId === action.activeSpeaker.sessionId);

            if (index === -1) {
                return {
                    list: [
                        ...state.list,
                        {
                            sessionId: action.activeSpeaker.sessionId,
                            caller: action.activeSpeaker.caller,
                            volume: action.activeSpeaker.volume,
                            isMuted: action.activeSpeaker.isMuted,
                            isHold: action.activeSpeaker.isHold
                        }
                    ]
                }
            }
            else {
                return {
                    list: [
                        ...state.list.slice(0, index),
                        {
                            ...state.list[index],
                            caller: action.activeSpeaker.caller,
                            volume: action.activeSpeaker.volume,
                            isMuted: action.activeSpeaker.isMuted,
                            isHold: action.activeSpeaker.isHold
                        },
                        ...state.list.slice(index + 1)
                    ]
                }
            }

        case C.DELETE_ACTIVESPEAKER:
            const indexDel = state.list.findIndex(session => session.sessionId === action.sessionId);

            if (indexDel > -1) {
                return {
                    list: [
                        ...state.list.slice(0, indexDel),
                        ...state.list.slice(indexDel + 1)
                    ],
                }
            }
            return state;


        case C.CLEAR_ACTIVESPEAKER:
            state = Object.assign({}, state, initialState);
            return state;

        default:
            return state;
    }
}
export default sessions;