import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

import RestRequest from '../../lib/RestRequest';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faMask, faPencilAlt, faToolbox, faTimes, faCircle, faHandPaper, faComment, faMicrophoneSlash, faMicrophone, faPhone, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'

import './participant.css';

// Redux
import { connect } from 'react-redux'
import { asideSetActiveTab } from '../../redux/actions/GeneralAction';


class Participant extends Component {
    enableTimerInterval = true;
    interval = null;

    constructor(props) {
        super(props);


        this.state = {
            tooltipOpen: false,
            tooltipDisc: false,
            tooltipHold: false,
            tooltipQeA: false,
            duration: parseInt(props.session.duration),
        };
    }

    componentDidMount() {
        if (this.enableTimerInterval) {
            this.interval = setInterval(() => {
                const newDuration = (this.state.duration || 0) + 1;
                // console.log("New participant duration", newDuration);
                this.setState({ duration: newDuration });
            }, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    toggleDisc = () => {
        this.setState({
            tooltipDisc: !this.state.tooltipDisc
        });
    }

    toggleHold = () => {
        this.setState({
            tooltipHold: !this.state.tooltipHold
        });
    }

    toggleMute = () => {
        this.setState({
            tooltipMute: !this.state.tooltipMute
        });
    }

    toggleQeA = () => {
        this.setState({
            tooltipQeA: !this.state.tooltipQeA
        });
    }

    secondsToTime(seconds) {
        if (!seconds) seconds = 0;
        return new Date(seconds * 1000).toISOString().substr(11, 8);
    }

    renderQA(qaMode, role, codec, id) {
        // console.log("renderQA", qaMode);
        if (role > 0 && (codec || '').toUpperCase() !== 'NOVOICE') {
            switch (qaMode) {
                case 1:
                    return <span id={`qea-icon-${id}`} className="fa-stack fa-layers fa-lg" style={{ position: 'absolute', bottom: '0', left: '0' }}>
                        <FontAwesomeIcon className="icon-background-green" icon={faCircle} />
                        <FontAwesomeIcon className="button-link" icon={faHandPaper} onClick={() => this.qaEngage(id)} />
                        <Tooltip key={`ttQA_${this.props.id}`} placement="top" target={`qea-icon-${id}`} isOpen={this.state.tooltipQeA} toggle={this.toggleQeA} >
                            Q&amp;A engage
                        </Tooltip>
                    </span>;

                case 2:
                    return <span id={`qea-icon-${id}`} className="fa-stack fa-layers fa-lg" style={{ position: 'absolute', bottom: '0', left: '0' }}>
                        <FontAwesomeIcon className="icon-background-red" icon={faCircle} />
                        <FontAwesomeIcon className="button-link" icon={faComment} onClick={() => this.qaDisengage(id)} />
                        <Tooltip key={`ttQA_${this.props.id}`} placement="top" target={`qea-icon-${id}`} isOpen={this.state.tooltipQeA} toggle={this.toggleQeA} >
                            Q&amp;A disengage
                        </Tooltip>
                    </span>;

                default:
                    return <div></div>;
            }
        }
    }

    renderRole(role, codec) {
        switch (role) {
            case 1:
                if ((codec || '').toUpperCase() === 'NOVOICE') {
                    return <FontAwesomeIcon size="lg" icon={faEye} color="orange" />
                }
                return <FontAwesomeIcon size="lg" icon={faPhone} color="orange" />

            case 2:
                if ((codec || '').toUpperCase() === 'NOVOICE') {
                    return <FontAwesomeIcon size="lg" icon={faEye} color="#00B6EE" />
                }
                return <FontAwesomeIcon size="lg" icon={faPhone} color="#00B6EE" />

            default:
                return <FontAwesomeIcon size="lg" icon={faEye} />
        }
    }

    renderHold(role, codec, holdMode, id) {
        // console.log("renderHold", holdMode);
        if (role > 0 && (codec || '').toUpperCase() !== 'NOVOICE') {
            return (holdMode === 1 ?
                <span id={`hold-icon-${id}`} className="fa-stack fa-layers fa-lg" style={{ position: 'absolute', bottom: '0', right: '0' }}>
                    <FontAwesomeIcon className="icon-background-red" icon={faCircle} />
                    <FontAwesomeIcon className="button-link" icon={faLock} onClick={() => this.holdCall(holdMode, id)} />
                    <Tooltip key={`ttHold_${this.props.id}`} placement="top" target={`hold-icon-${id}`} isOpen={this.state.tooltipHold} toggle={this.toggleHold} >
                        Hold: on
                    </Tooltip>
                </span>
                :
                <span id={`hold-icon-${id}`} className="fa-stack fa-layers fa-lg" style={{ position: 'absolute', bottom: '0', right: '0' }}>
                    <FontAwesomeIcon className="icon-background" icon={faCircle} />
                    <FontAwesomeIcon className="button-link" icon={faUnlock} onClick={() => this.holdCall(holdMode, id)} />
                    <Tooltip key={`ttHold_${this.props.id}`} placement="top" target={`hold-icon-${id}`} isOpen={this.state.tooltipHold} toggle={this.toggleHold} >
                        Hold: off
                    </Tooltip>
                </span>
            );

        }
    }

    renderMute(role, codec, muteMode, id) {
        if (role > 0 && (codec || '').toUpperCase() !== 'NOVOICE') {
            return (muteMode ? <span id={`mute-icon-${id}`} className="fa-stack fa-layers fa-lg" style={{ position: 'absolute', top: '0', left: '0' }}>
                <FontAwesomeIcon className="icon-background-red" icon={faCircle} />
                <FontAwesomeIcon className="button-link" icon={faMicrophoneSlash} onClick={() => this.muteCaller(muteMode, id)} />
                <Tooltip key={`ttMuted_${this.props.id}`} placement="top" target={`mute-icon-${id}`} isOpen={this.state.tooltipMute} toggle={this.toggleMute} >
                    Muted: on
                </Tooltip>
            </span>
                :
                <span id={`mute-icon-${id}`} className="fa-stack fa-layers fa-lg" style={{ position: 'absolute', top: '0', left: '0' }}>
                    <FontAwesomeIcon className="icon-background" icon={faCircle} />
                    <FontAwesomeIcon className="button-link" icon={faMicrophone} onClick={() => this.muteCaller(muteMode, id)} />
                    <Tooltip key={`ttMuted_${this.props.id}`} placement="top" target={`mute-icon-${id}`} isOpen={this.state.tooltipMute} toggle={this.toggleMute} >
                        Muted: off
                    </Tooltip>
                </span>
            );
        }
    }

    disconnectCall = (id) => {
        this.props.onDisconnect(id);
    }

    holdCall = (holdMode, id) => {
        this.props.onHold(holdMode, id);
    }

    muteCaller = (muteMode, id) => {
        this.props.onMute(muteMode, id);
    }

    qaEngage = (id) => {
        RestRequest.sendCallCommand(id, { "qaMode": 2 })
            .then(() => {
                console.log('Q&A Engage');
            })
            .catch(e => {
                console.log('qaEngage (CATCH)', e);
            });
    }

    qaDisengage = (id) => {
        RestRequest.sendCallCommand(id, { "qaMode": 0 })
            .then(() => {
                console.log('Q&A Disengage');

            })
            .catch(e => {
                console.log('qaDisengage (CATCH)', e);
            });
    }

    editProfile = (id) => {
        this.props.setActiveTab('3', id);
    }

    render() {
        if (this.props.session) {
            const { id, role, codec, customName, caller, holdMode, muteMode, qaMode, imageUrl, note, typeData /*, duration*/ } = this.props.session;

            return (
                <div className={this.props.className} id={this.props.id} key={this.props.id}>
                    <div style={{ position: 'relative' }}>
                        <div className="content-top">
                            {this.renderMute(role, codec, muteMode, id)}

                            {this.renderQA(qaMode, role, codec, id)}

                            {role === 1 && (codec || '').toUpperCase() === 'NOVOICE' && <FontAwesomeIcon icon={faMask} className="moderator_icon" />}
                            {role === 1 && (codec || '').toUpperCase() !== 'NOVOICE' && <FontAwesomeIcon icon={faToolbox} className="host_icon" />}

                            <div>
                                <i className={classnames("fa", "fa-crown", role, { hidden: true })}></i>
                                <img alt="avatar" className="content-image" src={imageUrl} />
                            </div>

                            <span id={`disconnect-icon-${this.props.id}`} className="fa-stack fa-layers fa-lg disconnect-participant">
                                <FontAwesomeIcon icon={faCircle} className="icon-background" />
                                <FontAwesomeIcon icon={faTimes} onClick={() => this.disconnectCall(id)} className="button-link" />
                            </span>
                            <Tooltip key={`ttHold_${this.props.id}`} placement="top" target={`disconnect-icon-${this.props.id}`} isOpen={this.state.tooltipDisc} toggle={this.toggleDisc} >
                                Disconnect
                            </Tooltip>

                            {this.renderHold(role, codec, holdMode, id)}
                        </div>

                        <div className="content-main">
                            <p className="infoCaller">
                                {customName || (caller.indexOf("anonymous") >= 0 ? "anonymous #" + id : caller)}
                                {typeData !== 1 ?
                                    <FontAwesomeIcon id="editProfile" icon={faPencilAlt} size="lg" onClick={(e) => { e.preventDefault(); this.editProfile(id) }} />
                                    :
                                    null
                                }
                            </p>
                            <p>{this.renderRole(role, codec)}&nbsp;&nbsp;{this.secondsToTime(this.state.duration)}</p>
                        </div>
                        {note ?
                            <Tooltip key={`ttNote_${this.props.id}`} placement="right" isOpen={this.state.tooltipOpen} target={this.props.id} toggle={this.toggle} style={{ backgroundColor: '#ff0000' }}>
                                {note}
                            </Tooltip>
                            :
                            null
                        }

                    </div>
                </div>
            )
        }
        else {
            return null;
        }


    }

}

const mapDispatchToProps = dispatch => ({
    setActiveTab: (tab, id) => dispatch(asideSetActiveTab(tab, id)),
})


export default connect(null, mapDispatchToProps)(Participant);