import C from '../constants'; 
 
const initialState = { 
    socketIO: null,
}; 

const socket = (state = initialState, action) => { 
    switch (action.type) { 
        case C.ADD_SOCKET: 
            state = Object.assign({}, state, {
                socketIO: action.socket,
            });
            return state;
            // return { 
            //     ...state, 
            //     socketIO: action.socket
            // } 

        case C.DEL_SOCKET:
            state = Object.assign({}, state, initialState);
            return state;        

        default: 
            return state; 
    } 
} 

export default socket;