const domains = {
    default: {
        id: "join",
        title: "JOIN Conference",
        description: "JOIN Conference",
        favicon: "img/favicon.png",
        logo: "img/join_512x512.jpg",
    },
    sparkle: {
        id: "sparkle",
        title: "Sparkle Conference",
        description: "Sparkle Conference",
        logo: "img/sparkle_512x512.jpg",
    },
    "hd-conference-call": {
        id: "hdc",
        title: "HDC Cloud Services",
        description: "HDC Cloud Services",
        favicon: "img/favicon_hdc.jpg",
        logo: "img/hdc_300x300.jpg",
    },
    "conferencecall.it": {
        id: "cc_it",
        title: "Conferencecall.it",
        description: "Conferencecall.it",
        favicon: "img/favicon_cc.it.jpg",
        logo: "img/cc.it_300x300.jpg",
    },
};

export function getPageHeadData() {
    let ret = domains.default;
    let assigned = false;
    Object.keys(domains).forEach(el => {
        if (assigned || el === "default") return;
        if (window.location.hostname.indexOf(el) >= 0) {
            // console.log("getPageHeadData ==>", el, domains[el]);
            ret = Object.assign({}, domains[el]);
            assigned = true;
        }
    });

    return ret;
};
