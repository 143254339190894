import { combineReducers } from 'redux';  
import auth from './AuthReducer';  
import conf from './ConferenceReducer';
import sessions from './SessionReducer';
import qaqueue from './qaQueueReducer';
import activeSpeaker from './ActiveSpeakerReducer';
import additionalSessions from './AdditionalSessionReducer';
import general from './GeneralReducer';
import repository from './RepositoryReducer'; 
import recordings from './RecordingsReducer'; 
import socket from './SocketReducer';
import webRTC from './WebRTCReducer';

const rootReducer = combineReducers({  
    authorization: auth,
    conference: conf,
    sessions: sessions,
    qaqueue: qaqueue,
    activeSpeaker: activeSpeaker,
    additionalSessions: additionalSessions,
    general: general,
    repository: repository,
    recordings: recordings,
    socket: socket,
    webRTC: webRTC,
});

export default rootReducer; 