import C from '../constants';

const initialState = {
    list: [],
};


const additionalSessions = (state = initialState, action) => {
    switch (action.type) {
        case C.UPDATE_ADDSESSION:
            console.log('AdditionalSessionReducer', action);

            if (state && state.list) {
                const index = state.list.findIndex(session => session.sessionId === action.additionalSession.sessionId);

                if (index === -1) {
                    return {
                        list: [
                            ...state.list,
                            {
                                sessionId: action.additionalSession.sessionId,
                                name: action.additionalSession.name,
                                note: action.additionalSession.note,
                                avatar: action.additionalSession.avatar
                            }
                        ]
                    }
                }
                else {
                    return {
                        list: [
                            ...state.list.slice(0, index),
                            {
                                ...state.list[index],
                                name: action.additionalSession.name,
                                note: action.additionalSession.note,
                                avatar: action.additionalSession.avatar
                            },
                            ...state.list.slice(index + 1)
                        ]
                    }
                }
            }

            return state;

        case C.CLEAR_ADDSESSIONS:
            state = Object.assign({}, state, initialState);
            return state;

        case C.DELETE_ADDSESSION:
            const index = state.list.findIndex(session => session.sessionId === action.sessionId);
            console.log("SESSION DEL", action.sessionId, state.list, index);
            if (index > -1) {
                return {
                    list: [
                        ...state.list.slice(0, index),
                        ...state.list.slice(index + 1)
                    ],
                }
            }
            return state;

        default:
            return state;
    }
}
export default additionalSessions;