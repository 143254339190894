import C from '../constants';

const initialState = {  
    liveConference: false,
    created: 0,
    duration: 0,
    holdMode: 0,
    id: 0,
    isRecording:false,
    isSecured: false,
    meetingNumber: 0,
    muteMode: 0,
    participantCount: 0,
    qaMode: 0,
    state: 0,
    subscriberName: '',
    type: 0  
};

const conference = (state = initialState, action) => {    
    switch (action.type) {
        case C.CONFERENCE_ON:     
            console.log('ConferenceReducers', action);
               
            state = Object.assign({}, state, {
                liveConference: true,
                created: action.conference.created,
                duration: action.conference.duration,
                holdMode: action.conference.holdMode,
                id: action.conference.id,
                isRecording: action.conference.isRecording,
                isSecured: action.conference.isSecured,
                meetingNumber: action.conference.meetingNumber,
                muteMode: action.conference.muteMode,
                participantCount: action.conference.participantCount,
                qaMode: action.conference.qaMode,
                state: action.conference.state,
                subscriberName: action.conference.subscriberName,
                type: action.conference.type               
            });
            return state;

        case C.CONFERENCE_OFF:
            const _state = Object.assign({}, state, initialState);
            return _state;

        case C.CONFERENCE_REC_ON:
            state = Object.assign({}, state, {
                isRecording: true
            });
            return state;
            
        case C.CONFERENCE_REC_OFF:
            state = Object.assign({}, state, {
                isRecording: false
            });
            return state;

        case C.CONFERENCE_HOLD_ON:
            state = Object.assign({}, state, {
                holdMode: 40
            });
            return state;

        case C.CONFERENCE_HOLD_OFF:
            state = Object.assign({}, state, {
                holdMode: 0
            });
            return state;

        case C.CONFERENCE_LOCK_ON:
            state = Object.assign({}, state, {
                isSecured: true
            });
            return state;

        case C.CONFERENCE_LOCK_OFF:
            state = Object.assign({}, state, {
                isSecured: false
            });
            return state;
          
        case C.CONFERENCE_MUTE_OFF:
            state = Object.assign({}, state, {
                muteMode: 0
            });
            return state;

        case C.CONFERENCE_MUTE_MUTE:
            state = Object.assign({}, state, {
                muteMode: 1
            });
            return state;

        case C.CONFERENCE_MUTE_LECTURE:
            state = Object.assign({}, state, {
                muteMode: 2
            });
            return state;

        case C.CONFERENCE_MUTE_CONTROL:
            state = Object.assign({}, state, {
                muteControlEnabled: action.status
            });
            return state;

        case C.CONFERENCE_QAMODE:                               
            state = Object.assign({}, state, {
                qaMode: action.status
            });
            return state;

        default:
            return state;
    }
}
export default conference;