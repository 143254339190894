import React, { Component } from 'react';
import {
	Container,
	Row, Col,
	Modal, ModalHeader, ModalBody, ModalFooter, Alert,
	CardGroup, Card, CardBody, CardFooter,
	Button, Label,
	Nav, NavItem, NavLink,
	Input, InputGroup, InputGroupButtonDropdown,
	DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import RestRequest from '../lib/RestRequest';
import { connect } from 'react-redux'
import { sendLogin, loginSuccess, loginError, loginResume } from '../redux/actions/AuthAction';
import classnames from 'classnames';
import { setConferenceOff } from '../redux/actions/ConferenceAction';
import { Helmet } from "react-helmet";
import { getPageHeadData } from "../config/domains";

var pjson = require('../../package.json');

class Login extends Component {
	constructor(props) {
		super(props);

		const pageData = getPageHeadData();

		this.state = {
			conferenceNumber: '',
			hostCode: '',
			participantAccessCode: '',
			phoneNumber: '',
			phonePrefix: '39',
			phoneCountry: 'it',
			modal: false,
			saveData: false,
			activeTab: 3,
			prefixDropdownOpen: false,
			logo: pageData.logo,
			instanceClass: pageData.id,
			pageTitle: pageData.title,
			pageDescription: pageData.description,
			pageFavicon: pageData.favicon,
		}
		this.modalToggle = this.modalToggle.bind(this);
		this.togglePrefixDropDown = this.togglePrefixDropDown.bind(this);

		if (this.props.match.params.id && this.props.match.params.hc) {
			this.doLogin(this.props.match.params.id, this.props.match.params.hc);
		}
	}

	toggleSaveData = (e) => {
		this.setState({
			saveData: !this.state.saveData
		})
	}

	modalToggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	togglePrefixDropDown(e) {
		// console.log("togglePrefixDropDown", e.target.value);
		const country = e.target.attributes.getNamedItem('country');
		if (country) {
			this.setState({
				phoneCountry: country.value,
				phonePrefix: e.target.value,
			});
		}
		this.setState({
			prefixDropdownOpen: !this.state.prefixDropdownOpen,
		});
	}

	setPhonePrefix(country, prefix) {
		this.setState({
			phoneCountry: country,
			phonePrefix: prefix,
		});
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	_handleKeyPress = e => {
		if (e.key === 'Enter') {
			this.onLogin(e);
		}
	}

	async logFetch(url) {
		try {
			const response = await fetch(url);
			console.log(await response.text());
		}
		catch (err) {
			console.log('fetch failed', err);
		}
	}

	async onLoginBtnEvent(tab, event) {
		switch (tab) {
			case 1: await this.onPhoneLogin(event); break;
			case 2: await this.onLogin(event); break;
			case 3: await this.onGuestLogin(event); break;
			default: break;
		}
	}

	async onLogin() {
		console.log("== LOGIN BY HOST CODE ==");

		const confId = this.state.conferenceNumber;
		const hostCode = this.state.hostCode;

		return await this.doLogin(confId, hostCode);
	}

	async doLogin(confId, hostCode) {
		const auth = {
			isModerator: 1,
			conferenceNumber: confId,
			hostAccessCode: hostCode,
			participantAccessCode: '',
			participantPhoneNumber: '',
			playBackNumber: 0,
			dialInNumber: 0,
			token: 'TOKENXXX',
		}

		this.props.sendLogin(confId, hostCode);
		this.props.setConferenceOff();

		const user = await RestRequest.login(confId, hostCode).catch(e => e);

		if (user === 'error') {
			this.modalToggle();
			return this.props.loginError('Errore durante il login!');
		}
		else {
			// Save data to local storage
			if (this.state.saveData) {
				localStorage.setItem('conferenceNumber', confId);
				localStorage.setItem('hostCode', hostCode);
				localStorage.setItem('phonePrefix', '');
				localStorage.setItem('phoneCountry', '');
				localStorage.setItem('phoneNumber', '');
				localStorage.setItem('participantAccessCode', '');
			}
			else {
				localStorage.removeItem('conferenceNumber');
				localStorage.removeItem('participantAccessCode');
				localStorage.removeItem('hostCode');
				localStorage.removeItem('phonePrefix');
				localStorage.removeItem('phoneCountry');
				localStorage.removeItem('phoneNumber');
			}

			// Retrieve participant e host code 
			user.keychain.forEach(element => {
				if (element.role === 2) auth.participantAccessCode += element.accessCode + ' ';
				// if (element.role === 1) auth.hostAccessCode += element.accessCode + ' ';
			});

			try {
				const didGroup = await RestRequest.getDidGroups(user.primaryDidGroupId).catch(e => e);
				if (didGroup && didGroup.dids && didGroup.dids.length > 0) {
					auth.playBackNumber = didGroup.dids[0].phoneNumber;
					auth.dialInNumber = didGroup.dids[0].phoneNumber;
				}
			} catch (e) {
				console.log("Try/catch getDidGroups", e);
			}

			// Go to Dashboard
			this.props.loginSuccess(auth);
			this.props.history.push('/conference');
		}
	}

	async onPhoneLogin() {
		console.log("== LOGIN BY PHONE ==");

		const participantAccessCode = this.state.participantAccessCode;
		const phoneNumber = [this.state.phonePrefix, this.state.phoneNumber].join("");

		const auth = {
			isModerator: 0,
			conferenceNumber: '',
			hostAccessCode: '',
			participantAccessCode: participantAccessCode,
			participantPhoneNumber: phoneNumber,
			token: 'TOKENXXX',
			callId: 0,
		}

		const user = await RestRequest.loginByPhone(participantAccessCode, phoneNumber).catch(e => e);

		if (user === 'error') {
			this.modalToggle();
			return this.props.loginError('Errore durante il login!');
		} else {
			// Save data to local storage
			if (this.state.saveData) {
				localStorage.setItem('participantAccessCode', participantAccessCode);
				localStorage.setItem('phonePrefix', this.state.phonePrefix);
				localStorage.setItem('phoneCountry', this.state.phoneCountry);
				localStorage.setItem('phoneNumber', this.state.phoneNumber);
				localStorage.setItem('hostCode', '');
			}
			else {
				localStorage.removeItem('conferenceNumber');
				localStorage.removeItem('participantAccessCode');
				localStorage.removeItem('phonePrefix');
				localStorage.removeItem('phoneCountry');
				localStorage.removeItem('phoneNumber');
				localStorage.removeItem('hostCode');
			}

			// Retrieve participant e host code 
			auth.callId = user.id;
			auth.conferenceNumber = user.meetingNumber;

			// Go to Dashboard
			this.props.loginSuccess(auth);
			this.props.history.push('/conference');
		}
	}

	async onGuestLogin() {
		console.log("== LOGIN ANONYMOUS ==");

		const participantAccessCode = this.state.participantAccessCode;

		if (!participantAccessCode) {
			this.modalToggle();
			return this.props.loginError('Errore durante il login!');
		}

		const auth = {
			isModerator: 0,
			conferenceNumber: '',
			hostAccessCode: '',
			participantAccessCode: participantAccessCode,
			participantPhoneNumber: '',
			token: 'TOKENXXX',
			callId: 0,
		}

		const user = await RestRequest.loginByRoom(participantAccessCode).catch(e => e);

		if (user === 'error') {
			this.modalToggle();
			return this.props.loginError('Errore durante il login!');
		} else {
			// Save data to local storage
			if (this.state.saveData) {
				localStorage.setItem('participantAccessCode', participantAccessCode);
				localStorage.setItem('phonePrefix', '');
				localStorage.setItem('phoneCountry', '');
				localStorage.setItem('phoneNumber', '');
				localStorage.setItem('hostCode', '');
			}
			else {
				localStorage.removeItem('conferenceNumber');
				localStorage.removeItem('participantAccessCode');
				localStorage.removeItem('phonePrefix');
				localStorage.removeItem('phoneCountry');
				localStorage.removeItem('phoneNumber');
				localStorage.removeItem('hostCode');
			}

			// Retrieve participant e host code 
			// auth.callId = "";
			auth.conferenceNumber = user.meetingNumber;

			// Go to Dashboard
			this.props.loginSuccess(auth);
			this.props.history.push('/conference');
		}
	}

	toggleTab(tabNum) {
		this.setState({
			activeTab: tabNum
		});
	}

	componentDidMount() {
		if (localStorage.getItem('conferenceNumber') || localStorage.getItem('participantAccessCode')) {
			this.setState({
				saveData: true,
				conferenceNumber: localStorage.getItem('conferenceNumber'),
				hostCode: localStorage.getItem('hostCode'),
				participantAccessCode: localStorage.getItem('participantAccessCode'),
				phoneNumber: localStorage.getItem('phoneNumber'),
				phonePrefix: localStorage.getItem('phonePrefix') || '39',
				phoneCountry: localStorage.getItem('phoneCountry') || 'it',
			});
			if ((localStorage.getItem('participantAccessCode') || "").length > 0) {
				(localStorage.getItem('phoneNumber') || "").length === 0 ? this.toggleTab(3) : this.toggleTab(2);
			}
		}
	}

	render() {
		return (
			<div className={classnames("app flex-row align-items-center login-page", this.state.instanceClass)}>

				<Helmet>
					<title>{this.state.pageTitle}</title>
					<meta name="description" content={this.state.pageDescription}></meta>
					<link rel="shortcut icon" href={this.state.pageFavicon}></link>
				</Helmet>

				<Modal isOpen={this.state.modal} toggle={this.modalToggle} className="modal-danger">
					<ModalHeader toggle={this.modalToggle}>
						Attention!
					</ModalHeader>
					<ModalBody>
						Your credentials do not match our records. Please try again.
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={this.modalToggle}>Ok</Button>
					</ModalFooter>
				</Modal>

				<Container>
					{this.props.alert ?
						<Row className="justify-content-center">
							<Col md="8">
								<Alert isOpen={true} color={this.props.alertColor}>
									{this.props.alertMessage}
								</Alert>
							</Col>
						</Row>
						: null
					}

					<Row className="justify-content-center">
						<Col md="8">
							<CardGroup>
								<Card>
									<CardBody className="p-4">
										<h1>Login</h1>

										<Nav fill pills className="mb-4">
											<NavItem>
												<NavLink className={classnames("pointer", { "active bg-success": this.state.activeTab === 3 })}
													onClick={() => { this.toggleTab(3); }}>
													<i className="icon-mustache"></i> Guest
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames("pointer", { "active bg-success": this.state.activeTab === 1 })}
													onClick={() => { this.toggleTab(1); }}>
													<i className="icon-phone"></i> By phone
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink className={classnames("pointer", { "active bg-success": this.state.activeTab === 2 })}
													onClick={() => { this.toggleTab(2); }}>
													<i className="icon-earphones-alt"></i> Moderator
												</NavLink>
											</NavItem>
										</Nav>

										<div className={classnames(this.state.activeTab === 1 ? "" : "hidden")}>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="icon-user"></i>
													</span>
												</div>
												<Input id="simpleParticipantAccessCode" type="text" placeholder="Access Code" onChange={this.handleChange} onKeyPress={this._handleKeyPress} value={this.state.participantAccessCode} />
											</InputGroup>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="icon-phone"></i>
													</span>
												</div>
												<InputGroupButtonDropdown addonType="append" isOpen={this.state.prefixDropdownOpen} toggle={this.togglePrefixDropDown}>
													<DropdownToggle caret>
														<i className={classnames("flag-icon", "flag-icon-" + this.state.phoneCountry)} title={this.state.phoneCountry}></i>
														(+{this.state.phonePrefix})
													</DropdownToggle>
													<DropdownMenu modifiers={{
														setMaxHeight: {
															enabled: true,
															order: 890,
															fn: (data) => {
																return {
																	...data,
																	styles: {
																		...data.styles,
																		overflow: 'auto',
																		maxHeight: 250,
																	},
																};
															},
														},
													}}>
														<DropdownItem header>Phone prefix</DropdownItem>
														{this.printPrefixDropdownItems()}
													</DropdownMenu>
												</InputGroupButtonDropdown>
												<Input id="phoneNumber" type="text" placeholder="Phone Number" onChange={this.handleChange} onKeyPress={this._handleKeyPress} value={this.state.phoneNumber} />
											</InputGroup>
										</div>
										<div className={classnames(this.state.activeTab === 2 ? "" : "hidden")}>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="icon-user"></i>
													</span>
												</div>
												<Input id="conferenceNumber" type="text" placeholder="Conference ID" onChange={this.handleChange} onKeyPress={this._handleKeyPress} value={this.state.conferenceNumber} />
											</InputGroup>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="icon-lock"></i>
													</span>
												</div>
												<Input id="hostCode" type="text" placeholder="Host Code" onChange={this.handleChange} onKeyPress={this._handleKeyPress} value={this.state.hostCode} />
											</InputGroup>
										</div>
										<div className={classnames(this.state.activeTab === 3 ? "" : "hidden")}>
											<InputGroup className="mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="icon-user"></i>
													</span>
												</div>
												<Input id="participantAccessCode" type="text" placeholder="Access Code" onChange={this.handleChange} onKeyPress={this._handleKeyPress} value={this.state.participantAccessCode} />
											</InputGroup>
										</div>
										<Row>
											<Col xs="5">
												<Button color="primary" className="px-4" onClick={(event) => this.onLoginBtnEvent(this.state.activeTab, event)} >Login</Button>
											</Col>
											<Col xs="7" className="text-right">
												<Label for="label1" style={{ fontSize: '12px' }}>Remember me&nbsp;</Label>
												<Label id="label1" className="switch switch-text switch-primary switch-md">
													<Input id="ckbSaveData" type="checkbox" className="switch-input" onChange={this.toggleSaveData} checked={this.state.saveData} />
													<span className="switch-label" data-on="On" data-off="Off"></span>
													<span className="switch-handle"></span>
												</Label>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										v{pjson.version} - (<i>{process.env.NODE_ENV || "development"}</i>)
									</CardFooter>
								</Card>
								<Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
									<CardBody className="text-center">
										<div>
											<img src={this.state.logo} className="img-thumbnail" alt="logo" />
										</div>
									</CardBody>
								</Card>
							</CardGroup>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	printPrefixDropdownItems() {
		const countries = require('../common/countries');
		let out = [];
		countries.internationalPrefixes.forEach(el => {
			const key = el.code.toLowerCase();
			const value = el.dial_code.replace('+', '');
			out.push(<DropdownItem key={key} value={value} country={key}><i className={"flag-icon flag-icon-" + key} title={key}></i> +{value} - {el.name}</DropdownItem>);
		});

		return out;
	}
}


// These props come from the application's
// state when it is started
const mapStateToProps = state => ({
	isModerator: state.authorization.isModerator,
	token: state.authorization.token,
	conferenceNumber: state.authorization.conferenceNumber,
	isLogged: state.authorization.isLogged,
	loginErrorMessage: state.authorization.loginErrorMessage,
	activeTab: state.activeTab,
	alert: state.general.alert,
	alertMessage: state.general.alertMessage,
	alertColor: state.general.alertColor,
})

const mapDispatchToProps = dispatch => ({
	sendLogin: (conferenceNumber, hostCode) => dispatch(sendLogin(conferenceNumber, hostCode)),
	// sendParticipantLogin: (conferenceNumber, phonePrefix, phoneNumber) => dispatch(sendParticipantLogin(conferenceNumber, phonePrefix, phoneNumber)),
	loginSuccess: token => dispatch(loginSuccess(token)),
	loginError: errorMessage => dispatch(loginError(errorMessage)),
	loginResume: () => dispatch(loginResume()),
	setConferenceOff: () => dispatch(setConferenceOff()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Login);

