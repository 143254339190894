import C from '../constants';

export const asideSetActiveTab = (tab, sessionId) => ({
    type: C.ASIDE_SET_ACTIVE_TAB,
    tab,
    sessionId
})

export const setGraphicMode = (isGraphicMode) => ({
    type: C.SET_GRAPHIC_MODE,
    isGraphicMode
})

export const showAlert = (message, color) => ({
    type: C.SHOW_ALERT,
    message,
    color
})

export const hideAlert = (message, color) => ({
    type: C.HIDE_ALERT
})