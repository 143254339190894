import React, { Component } from "react";
import { Col, Row, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';

import './uploadimage.css';


class UploadImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            imageSize: 300,
            imageZoom: 1,
        };
        this.onDrop = this.onDrop.bind(this);
        this.getEditorArea = this.getEditorArea.bind(this);
    }

    componentDidMount() {
        this.setState({
            image: this.props.image
        })
    }


    onDrop(acceptedFiles, rejectedFiles) {
        const promise = new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.readAsDataURL(acceptedFiles[0])

            reader.onload = () => {
                if (!!reader.result) {
                    resolve(reader.result)
                }
                else {
                    reject(Error("Failed converting to base64"))
                }
            }
        })

        promise.then(result => {
            // dispatch or do whatever you need with result
            this.setState({
                image: acceptedFiles[0],
            })
            this.props.onUploadImageChange(result);

        }, err => {
            console.log('UploadImage (onDrop) error', err)
        })

    }

    setEditorRef = (editor) => this.editor = editor

    avatarImageChange = () => {
        if (this.editor) {
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = this.editor.getImageScaledToCanvas().toDataURL();
            this.props.onUploadImageChange(canvasScaled);
        }
    }


    onSliderSizeChange = (value) => {
        this.setState({
            imageSize: value,
        });
    }

    onSliderZoomChange = (value) => {
        this.setState({
            imageZoom: value,
        });
    }

    getEditorArea() {
        let preview = (

            <Dropzone
                disableClick
                style={{ position: "relative" }}
                // accept={accept}
                onDrop={this.onDrop}
                // onDragEnter={this.onDragEnter}
                // onDragLeave={this.onDragLeave}
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="text-center">
                        <FontAwesomeIcon icon={ faCloudUploadAlt } size="4x" className="mb-1" />
                        <div className="dz-message">Drop files here or click to select files to upload.</div>
                    </div>
                )}
            </Dropzone>
        );

        if (this.state.image && this.state.image.preview !== null) {
            preview = (
                <div>
                    <Row>
                        <Col>
                            <h3>Select image</h3>
                            <AvatarEditor
                                image={this.state.image}
                                width={this.state.imageSize}
                                height={this.state.imageSize}
                                border={20}
                                borderRadius={0}
                                color={[250, 255, 255, 0.6]} // RGBA
                                scale={this.state.imageZoom}
                                rotate={0}
                                ref={this.setEditorRef}
                                onImageChange={this.avatarImageChange}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <p>Image zoom</p>
                                <Slider value={this.state.imageZoom} min={1} max={2} step={0.1} onChange={this.onSliderZoomChange} />
                            </Row>
                            <Row>
                                <p>Image size</p>
                                <Slider value={this.state.imageSize} min={100} max={500} onChange={this.onSliderSizeChange} />
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="warning" type="button" onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                    image: null
                                });
                            }}>Add new image</Button>
                        </Col>
                    </Row>
                </div>
            );
        }
        return preview;
    }

    render() {
        return (
            <div style={{ float: 'left', width: '100%' }}>
                {this.getEditorArea()}
            </div>
        )
    }

}

export default UploadImage;