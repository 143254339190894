import C from '../constants';

const initialState = {
    list: [],
    count: 0,
};

const sessions = (state = initialState, action) => {
    switch (action.type) {
        case C.ADD_SESSION:
            if (state && state.list) {
                const index = state.list.findIndex(session => session.id === action.session.id);
                if (index < 0) {
                    return {
                        ...state,
                        list: [...state.list, {
                            accessCode: action.session.accessCode,
                            addressFrom: action.session.addressFrom,
                            addressTo: action.session.addressTo,
                            bridgeName: action.session.bridgeName,
                            callee: action.session.callee,
                            caller: action.session.caller,
                            codec: action.session.codec,
                            conferenceId: action.session.conferenceId,
                            connectionStatus: action.session.connectionStatus,
                            created: action.session.created,
                            customName: action.session.customName ? action.session.customName.replace(/'/g, '') : '',
                            dialout: action.session.dialout || false,
                            duration: action.session.duration,
                            gainLevel: action.session.gainLevel,
                            holdMode: action.session.holdMode,
                            id: action.session.id,
                            joined: action.session.joined,
                            meetingNumber: action.session.meetingNumber,
                            muteMode: action.session.muteMode,
                            nodeName: action.session.nodeName,
                            phoneNumber: action.session.phoneNumber,
                            presenterMedia: action.session.presenterMedia,
                            qaMode: action.session.qaMode,
                            role: action.session.role,
                            state: action.session.state,
                            imageUrl: action.session.imageUrl,
                            typeData: action.session.typeData,
                            note: action.session.note
                        }]
                    }
                }
            }
            return state;

        case C.UPDATE_SESSION_ALL:
            if (state && state.list) {
                const index = state.list.findIndex(session => session.id === action.session.id);
                if (index > -1) {
                    return {
                        ...state,
                        list: [
                            ...state.list.slice(0, index),
                            {
                                ...state.list[index],
                                accessCode: action.session.accessCode,
                                addressFrom: action.session.addressFrom,
                                addressTo: action.session.addressTo,
                                bridgeName: action.session.bridgeName,
                                callee: action.session.callee,
                                caller: action.session.caller || state.list[index].caller,
                                codec: action.session.codec,
                                conferenceId: action.session.conferenceId,
                                connectionStatus: action.session.connectionStatus,
                                created: action.session.created,
                                customName: action.session.customName ? action.session.customName.replace(/'/g, '') : '',
                                dialout: action.session.dialout || false,
                                duration: action.session.duration,
                                gainLevel: action.session.gainLevel,
                                holdMode: action.session.holdMode,
                                id: action.session.id,
                                joined: action.session.joined,
                                meetingNumber: action.session.meetingNumber,
                                muteMode: action.session.muteMode,
                                nodeName: action.session.nodeName,
                                phoneNumber: action.session.phoneNumber,
                                presenterMedia: action.session.presenterMedia,
                                qaMode: action.session.qaMode,
                                role: action.session.role,
                                state: action.session.state,
                                imageUrl: action.session.imageUrl,
                                typeData: action.session.typeData,
                                note: action.session.note
                            },
                            ...state.list.slice(index + 1)
                        ]
                    }
                }
            }
            return state;

        case C.UPDATE_SESSION:
            if (state && state.list) {
                const index = state.list.findIndex(session => session.id === action.session.id);

                if (index > -1) {
                    return {
                        ...state,
                        list: [
                            ...state.list.slice(0, index),
                            {
                                ...state.list[index],
                                customName: action.session.name,
                                imageUrl: action.session.avatar,
                                typeData: action.session.typeData,
                                note: action.session.note,
                            },
                            ...state.list.slice(index + 1)
                        ]
                    }
                }
            }
            return state;

        case C.UPDATE_SESSION_DURATION:
            if (state && state.list) {
                const index = state.list.findIndex(session => session.id === action.id);

                if (index > -1) {
                    return {
                        ...state,
                        list: [
                            ...state.list.slice(0, index),
                            {
                                ...state.list[index],
                                duration: action.duration
                            },
                            ...state.list.slice(index + 1)
                        ]
                    }
                }
            }
            return state;

        case C.DELETE_SESSION:
            const index = state.list.findIndex(session => session.id === action.id);

            if (index > -1) {
                return {
                    ...state,
                    list: [
                        ...state.list.slice(0, index),
                        ...state.list.slice(index + 1)
                    ],
                }
            }
            return state;

        case C.SHOW_SESSION:
            return state.list;

        case C.CLEAR_SESSIONS:
            state = Object.assign({}, state, initialState);
            return state;

        case C.COUNT_SESSIONS:
            state = Object.assign({}, state, {
                count: action.count
            });
            return state;

        case C.ADD_SESSION_ID:
            state = Object.assign({}, state, {
                sessionId: action.sessionId
            });
            return state;

        case C.ADD_AUDIO_KEY:
            state = Object.assign({}, state, {
                audioKey: action.audioKey
            })
            return state;

        default:
            return state;
    }
}
export default sessions;