
// ATTENZIONE!!! Le impostazioni sono nel file webpack.config.js

const env = process.env.NODE_ENV || "development"; // 'development' or 'production'

const development = {
    development: true,
    bridgeUrl: 'https://joinaudio4.joinconferencing.com/wyderef',
    apiServerUrl: "http://localhost:8081",
    sipServerUrl: "http://0.0.0.0:8181",
    OPENVIDU_SERVER_URL: 'https://webrtc.joinconferencing.com:4443',
    OPENVIDU_SERVER_SECRET: 'NwCgAmzB5Q3Gk8P6',
};

const production = {
    development: false,
    bridgeUrl: 'https://joinaudio4.joinconferencing.com/wyderef',
    apiServerUrl: "https://moderator.joinconferencing.com/ppapi",
    sipServerUrl: "https://bridge.joinconferencing.com",
    OPENVIDU_SERVER_URL: 'https://webrtc.joinconferencing.com:4443',
    OPENVIDU_SERVER_SECRET: 'NwCgAmzB5Q3Gk8P6',
};

const config = {
    development,
    production
};

module.exports = config[env];
