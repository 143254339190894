const constant = {
    ADD_SOCKET: "ADD_SOCKET",
    DEL_SOCKET: "DEL_SOCKET",

    ASIDE_SET_ACTIVE_TAB: "ASIDE_SET_ACTIVE_TAB",
    SET_GRAPHIC_MODE: "SET_GRAPHIC_MODE",
    SHOW_ALERT: "SHOW_ALERT",
    HIDE_ALERT: "HIDE_ALERT",

    ADD_USER: "ADD_USER",
    DELETE_USER: "DELETE_USER",
    SEND_LOGIN: 'SEND_LOGIN',
    SEND_LOGOUT: 'SEND_LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGIN_RESUME: 'LOGIN_RESUME',

    CONFERENCE_ON: 'CONFERENCE_ON',
    CONFERENCE_OFF: 'CONFERENCE_OFF',
    CONFERENCE_HOLD_ON: 'CONFERENCE_HOLD_ON',
    CONFERENCE_HOLD_OFF: 'CONFERENCE_HOLD_OFF',
    CONFERENCE_REC_ON: 'CONFERENCE_REC_ON',
    CONFERENCE_REC_OFF: 'CONFERENCE_REC_OFF',
    CONFERENCE_LOCK_ON: 'CONFERENCE_LOCK_ON',
    CONFERENCE_LOCK_OFF: 'CONFERENCE_LOCK_OFF',
    CONFERENCE_MUTE_OFF: 'CONFERENCE_MUTE_OFF',
    CONFERENCE_MUTE_MUTE: 'CONFERENCE_MUTE_MUTE',
    CONFERENCE_MUTE_LECTURE: 'CONFERENCE_MUTE_LECTURE',
    CONFERENCE_MUTE_CONTROL: 'CONFERENCE_MUTE_CONTROL',

    CONFERENCE_QAMODE: 'CONFERENCE_QAMODE',

    ADD_SESSION: 'ADD_SESSION',
    UPDATE_SESSION: 'UPDATE_SESSION',
    UPDATE_SESSION_ALL: 'UPDATE_SESSION_ALL',
    UPDATE_SESSION_DURATION: 'UPDATE_SESSION_DURATION',
    DELETE_SESSION: 'DELETE_SESSION',
    SHOW_SESSION: 'SHOW_SESSION',
    CLEAR_SESSIONS: 'CLEAR_SESSIONS',
    COUNT_SESSIONS: 'COUNT_SESSIONS',
    ADD_SESSION_ID: 'ADD_SESSION_ID',
    ADD_AUDIO_KEY: 'ADD_AUDIO_KEY',

    ADD_QAQUEUE: 'ADD_QAQUEUE',
    UPD_QAQUEUE: 'UPD_QAQUEUE',
    DEL_QAQUEUE: 'DEL_QAQUEUE',
    CLEAR_QAQUEUE: 'CLEAR_QAQUEUE',

    SET_ACTIVESPEAKER: 'ADD_ACTIVESPEAKER',
    DELETE_ACTIVESPEAKER: 'DELETE_ACTIVESPEAKER',
    CLEAR_ACTIVESPEAKER: 'CLEAR_ACTIVESPEAKER',

    UPDATE_ADDSESSION: 'UPDATE_ADDSESSION',
    CLEAR_ADDSESSIONS: 'CLEAR_ADDSESSIONS',
    DELETE_ADDSESSION: 'DELETE_ADDSESSION',

    REPO_ADD_FILES: 'REPO_ADD_FILES',
    REPO_ADD_FILE: 'REPO_ADD_FILE',
    REPO_CLEAR_FILES: 'REPO_CLEAR_FILES',

    RECORDING_ADD_FILES: 'RECORDING_ADD_FILES',
    RECORDING_ADD_FILE: 'RECORDING_ADD_FILE',
    RECORDING_CLEAR_FILES: 'RECORDING_CLEAR_FILES',

    START_WEBRTC_SESSION: 'START_WEBRTC_SESSION',
    STOP_WEBRTC_SESSION: 'STOP_WEBRTC_SESSION',

}

export default constant;