import C from '../constants';

export const sendLogin = (conferenceNumber, hostAccessCode) => ({
    type: C.SEND_LOGIN,
    conferenceNumber,
    hostAccessCode
})

export const loginSuccess = auth => ({
    type: C.LOGIN_SUCCESS,
    auth   
})

export const loginError = errorMessage => ({
    type: C.LOGIN_ERROR,   
    errorMessage
})

export const loginResume = () => ({
    type: C.LOGIN_RESUME
})

export const sendLogout = () => ({
    type: C.SEND_LOGOUT
})

