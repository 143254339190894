import C from '../constants';

export const addQAqueue = qaqueue => ({
    type: C.ADD_QAQUEUE,
    qaqueue,
})

export const updQAqueue = qaqueue => ({
    type: C.UPD_QAQUEUE,
    qaqueue,
})

export const delQAqueue = sessionId => ({
    type: C.DEL_QAQUEUE,
    sessionId,
})

export const clearQAqueue = () => ({
    type: C.CLEAR_QAQUEUE    
})