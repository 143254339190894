import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import classnames from 'classnames';
import { Helmet } from "react-helmet";

import { getPageHeadData } from "../config/domains";

import Header from './Header/Header';
// import Sidebar from './Sidebar/Sidebar';
import Aside from './Aside/Aside';
import Footer from './Footer/Footer';

import ConferenceTable from './ConferenceTable/ConferenceTable';

class Full extends Component {

  render() {

    const pageData = getPageHeadData();

    return (
      <div className={classnames("app", pageData.id)}>

        <Helmet>
          <title>{pageData.title}</title>
          <meta name="description" content={pageData.description}></meta>
          <link rel="shortcut icon" href={pageData.favicon}></link>
        </Helmet>

        <Header />

        <div className="app-body">

          { /* <Sidebar {...this.props} /> */ }

          <main className="main">
            <Container fluid style={{ paddingTop: '10px' }}>
              <Switch>
                <Route path="/conference" name="Conference View" component={ConferenceTable} />
                <Redirect from="/" to="/conference" />
              </Switch>
            </Container>
          </main>

          <Aside />

        </div>

        <Footer />

      </div>
    );
  }
}

export default Full;
