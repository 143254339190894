import React, { Component } from 'react';
import './StreamComponent.css';
import OvVideoComponent from './OvVideo';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var StreamComponent = function (_Component) {
    _inherits(StreamComponent, _Component);

    function StreamComponent(props) {
        _classCallCheck(this, StreamComponent);

        var _this = _possibleConstructorReturn(this, (StreamComponent.__proto__ || Object.getPrototypeOf(StreamComponent)).call(this, props));

        _this.state = { nickname: _this.props.user.getNickname(), showForm: false, mutedSound: false, isFormValid: true };
        _this.handleChange = _this.handleChange.bind(_this);
        _this.handlePressKey = _this.handlePressKey.bind(_this);
        _this.toggleNicknameForm = _this.toggleNicknameForm.bind(_this);
        _this.toggleSound = _this.toggleSound.bind(_this);
        return _this;
    }

    _createClass(StreamComponent, [{
        key: 'handleChange',
        value: function handleChange(event) {
            this.setState({ nickname: event.target.value });
            event.preventDefault();
        }
    }, {
        key: 'toggleNicknameForm',
        value: function toggleNicknameForm() {
            console.log('toggleNicknameForm', this);
            if (this.props.user.isLocal()) {
                this.setState({ showForm: !this.state.showForm });
            }
        }
    }, {
        key: 'toggleSound',
        value: function toggleSound() {
            this.setState({ mutedSound: !this.state.mutedSound });
        }
    }, {
        key: 'handlePressKey',
        value: function handlePressKey(event) {
            if (event.key === 'Enter') {
                console.log(this.state.nickname);
                if (this.state.nickname.length >= 3 && this.state.nickname.length <= 20) {
                    this.props.handleNickname(this.state.nickname);
                    this.toggleNicknameForm();
                    this.setState({ isFormValid: true });
                } else {
                    this.setState({ isFormValid: false });
                }
            }
        }
    }, {
        key: 'render',
        value: function render() {
            return React.createElement(
                'div',
                { className: 'OT_widget-container' },
                this.props.user !== undefined && this.props.user.getStreamManager() !== undefined ? (
                    <div className="pointer nickname">
                        {this.state.showForm ? (
                            <FormControl id="nicknameForm">
                                <IconButton color="inherit" id="closeButton" onClick={this.toggleNicknameForm}>
                                    <HighlightOff />
                                </IconButton>
                                <InputLabel htmlFor="name-simple" id="label">Nickname</InputLabel>
                                <Input
                                    color="inherit"
                                    id="input"
                                    value={this.state.nickname}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handlePressKey}
                                    required
                                />
                                {!this.state.isFormValid && this.state.nickname.length <= 3 && (
                                    <FormHelperText id="name-error-text">Nickname is too short!</FormHelperText>
                                )}
                                {!this.state.isFormValid && this.state.nickname.length >= 20 && (
                                    <FormHelperText id="name-error-text">Nickname is too long!</FormHelperText>
                                )}
                            </FormControl>
                        ) : (
                                <div>
                                    <span id="nickname">{this.props.user.getNickname()}</span>
                                    {false && this.props.user.isLocal() && <span onClick={this.toggleNicknameForm}> (edit)</span>}
                                </div>
                            )}
                    </div>
                ) : null,
                this.props.user !== undefined && this.props.user.getStreamManager() !== undefined ? React.createElement(
                    'div',
                    { className: 'streamComponent' },
                    React.createElement(OvVideoComponent, { user: this.props.user, mutedSound: this.state.mutedSound })
                    /* ,
                    React.createElement(
                        'div',
                        { id: 'statusIcons' },
                        !this.props.user.isVideoActive() ? React.createElement(
                            'div',
                            { id: 'camIcon' },
                            React.createElement(VideocamOff, { id: 'statusCam' })
                        ) : null,
                        !this.props.user.isAudioActive() ? React.createElement(
                            'div',
                            { id: 'micIcon' },
                            React.createElement(MicOff, { id: 'statusMic' })
                        ) : null
                    ),
                    React.createElement(
                        'div',
                        null,
                        !this.props.user.isLocal() && React.createElement(
                            IconButton,
                            { id: 'volumeButton', onClick: this.toggleSound },
                            this.state.mutedSound ? React.createElement(VolumeOff, { color: 'secondary' }) : React.createElement(VolumeUp, null)
                        )
                    )
                    */
                ) : null
            );
        }
    }]);

    return StreamComponent;
}(Component);

export default StreamComponent;